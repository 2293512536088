import React from 'react';
import { withRouter } from 'react-router-dom';
import './balance.css';

const BalanceAnalysisSummaryTable = ({balanceItem}) => {
    var partRender = balanceItem === null ? "" : renderSummaryTable(balanceItem)
    return (
        <div>
            {partRender}
        </div>
    )
}


function renderSummaryTable(balanceItem) {  
    return (
        <div className="summaryTable">
            <strong>Bilans</strong>
            <div>
                <table className="summaryTable">
                    <thead>
                        <tr >
                            <th className="cellHeader">Bilans początkowy</th>
                            <th className="cellHeader">Wpłaty</th>
                            <th className="cellHeader">Wypłaty</th>
                            <th className="cellHeader">Saldo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="cellData">{balanceItem.startBalance} zł</td>
                            <td className="cellData">{balanceItem.totalIncome} zł</td>
                            <td className="cellData">{balanceItem.totalCost} zł</td>
                            <td className="cellData"><strong>{balanceItem.balance} zł </strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default withRouter(BalanceAnalysisSummaryTable);