import React, { Component } from 'react';
import { MDBContainer } from 'mdbreact';
import BankAccountTable from '../../features/bankAccount/bankAccountTable';
import GrantSheetMenu from '../menu/menuNavBar';
import './bankAccountList.css'

class BankAccountList extends Component {
    state = {}
    render() {
        return (
            <div>
                <GrantSheetMenu />
                <MDBContainer fluid>
                    <BankAccountTable />
                </MDBContainer>
            </div>
        );
    }
}

export default BankAccountList;