import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
} from "mdbreact";
import Spinner from "../../common components/spinner/spinner";
import AddCostButton from "../../features/cost/addCostButton";
import costService from "./costService";
import client_path from "../../../constants/client_URL_paths";
import SummaryTable from "../../table/summaryTable";
import "./cost.css";

class CostTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      columns: [
        {
          header: "Data",
          field: "costDate",
          sort: "disabled",
          width: 120,
          orderType: "date",
        },
        {
          header: "Nr rachunku / dotacji",
          field: "documentNumber",
          sort: "asc",
          width: 220,
        },
        {
          header: "Kwota",
          field: "amount",
          isSummary: true,
          type: "currency",
          sort: "asc",
          width: 100,
        },
        {
          header: "Adresat / opis",
          field: "description",
          sort: "asc",
          width: 390,
        },
        { header: "Subkonto", field: "projectName", sort: "asc", width: 200 },
        {
          header: "Rodzaj kosztu",
          field: "costTypeName",
          sort: "asc",
          width: 200,
        },
        { header: "Uwagi", field: "note", sort: "asc", width: 200 },
        { header: "", field: "actions", sort: false, width: 130 },
      ],

      confirmDelete: false,
      costToDelete: null,
      isLoaded: false,
    };

    this.searchCostsCallback = this.searchCostsCallback.bind(this);
  }

  async componentDidMount() {
    var today = new Date();
    var dateFrom = new Date(today.getFullYear(), 0, 1);
    var dateTo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59
    );

    this.searchCosts(dateFrom, dateTo);
  }

  async searchCosts(from, to) {
    var request = {
      dateFrom: from,
      dateTo: to,
    };

    const items = await costService.getCosts(request);
    const data = items.map((cost) => {
      return {
        id: cost.id,
        costDate: cost.costDate,
        projectName: cost.projectName,
        bankAccountName: cost.bankAccountName,
        documentNumber: cost.documentNumber,
        amount: cost.amount,
        costTypeName: cost.costTypeName,
        description: cost.description,
        note: cost.note,
        actions: (
          <>
            {cost.isDotation ? (
              <></>
            ) : (
              <span
                className="editButton"
                onClick={() => this.redirectToCostEditForm(cost)}
              >
                Edytuj |{" "}
              </span>
            )}
            {cost.isDotation ? (
              <></>
            ) : (
              <span
                className="editButton"
                onClick={() => this.confirmDelete(cost)}
              >
                Usuń
              </span>
            )}
          </>
        ),
      };
    });

    this.setState({ data, isLoaded: true });
  }

  searchCostsCallback(from, to) {
    var dateFrom = new Date(from);
    var dateTo = new Date(to);

    this.searchCosts(dateFrom, dateTo);
  }

  cancelDelete = () => {
    this.setState({
      confirmDelete: !this.state.confirmDelete,
    });
  };

  confirmDelete(cost) {
    this.setState({ costToDelete: cost, confirmDelete: true });
  }

  deleteCost() {
    const { costToDelete } = this.state;
    costService.deleteCost(costToDelete.id).then(() => {
      window.location.reload();
    });
  }

  redirectToCostEditForm(cost) {
    this.props.history.push(`${client_path.costEditForm}/${cost.id}`);
  }

  render() {
    const { columns, data, isLoaded, confirmDelete, costToDelete } = this.state;
    var deleteModal = "";

    if (confirmDelete) {
      deleteModal = (
        <MDBContainer>
          <MDBModal
            isOpen={this.state.confirmDelete}
            toggle={this.cancelDelete}
          >
            <MDBModalHeader toggle={this.cancelDelete}>
              Usuwanie kosztu
            </MDBModalHeader>
            <MDBModalBody>
              Czy na pewno chcesz usunąć koszt na kwotę '{costToDelete.amount}'
              ?
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="light" onClick={this.cancelDelete}>
                Nie
              </MDBBtn>
              <MDBBtn color="primary" onClick={this.deleteCost.bind(this)}>
                Tak
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
    }

    var renderedPart = !isLoaded ? (
      <Spinner />
    ) : (
      <div>
        <div className="d-flex justify-content-between">
          <h3 className="text-center pt-2">Koszty</h3>
          <AddCostButton />
        </div>
        <SummaryTable
          columns={columns}
          searchItemCallback={this.searchCostsCallback}
          showDateRangeSearch={true}
          data={data}
          defaultOrderBy="costDate"
          defaultOrder="desc"
        />
        {deleteModal}
      </div>
    );

    return <>{renderedPart}</>;
  }
}

export default withRouter(CostTable);
