import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authService from '../../../common-services/authService';
import userService from '../../features/user/userService';

const ProtectedRoute = ({ path, redirectPath, role, component: Component, render, ...rest }) => {

    if (role !== "" && typeof role !== "undefined") {
        var loggedUser = userService.getLoggedUser().then(() =>
        {
            if (!loggedUser.isAdministrator) {
                return <Redirect to={redirectPath} />
            }
        });
    }

    const userToken = authService.getTokenFromLocalStorage();
    if (!userToken) {
        return <Redirect to={redirectPath} />
    }

    return (
        <Route path={path} {...rest}
            render={props => {
                return Component ? <Component {...props} /> : render(props);
            }}
        />
    )
};

export default ProtectedRoute;