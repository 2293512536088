import httpService from "../../../common-services/httpService";
import server_path from "../../../constants/server_URL_paths";

export async function create(request) {
  const { data } = await httpService.post(
    `${server_path.dotations.create}`,
    request
  );
  return data;
}

export async function getById(dotationId) {
  const { data } = await httpService.get(
    `/dotation/getById/?dotationId=${dotationId}`
  );
  return data;
}

export async function search(request) {
  const { data } = await httpService.post(
    `${server_path.dotations.search}`,
    request
  );
  return data;
}

export async function update(request) {
  const { data } = await httpService.put("/dotation/update", request);
  return data;
}

export async function remove(dotationId) {
  const { data } = await httpService.delete(
    `${server_path.dotations.delete}?dotationId=${dotationId}`
  );
  return data;
}

export default {
  create,
  update,
  remove,
  getById,
  search,
};
