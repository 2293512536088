import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { MDBRow, MDBCol } from "mdbreact";
import Spinner from "../../common components/spinner/spinner";
import editForm_service from "./bankAccountService";
import client_path from "../../../constants/client_URL_paths";

import "./bankAccount.css";

class BankAccountEdit extends Component {
  state = {
    bankAccountInformation: {
      name: "",
      number: "",
      isActive: true,
    },
    loadedData: false,
  };
  async componentDidMount() {
    try {
      const bankAccountId = this.props.match.params.id;
      if (bankAccountId === "createBankAccount") {
        this.setState({ loadedData: true });
        return;
      }

      const foundBankAccount = await editForm_service.getBankAccountById(
        bankAccountId
      );
      this.setState({
        bankAccountInformation: foundBankAccount,
        loadedData: true,
      });
    } catch (ex) {
      throw Error(ex);
    }
  }

  handleCancel = () => {
    this.props.history.push(client_path.bankAccountList);
  };

  handleCheckBoxChange = (event) => {
    const { bankAccountInformation } = this.state;
    bankAccountInformation.isActive = event.target.checked;

    this.setState({ bankAccountInformation });
  };

  handleSave = async () => {
    try {
      const { bankAccountInformation } = this.state;
      const bankAccountId = this.props.match.params.id;
      if (bankAccountId === "createBankAccount") {
        await editForm_service.createBankAccount(bankAccountInformation);
      } else {
        await editForm_service.updateBankAccount(bankAccountInformation);
      }
      this.props.history.push(client_path.bankAccountList);
    } catch (ex) {
      console.log("error", ex);
    }
  };
  handleInputOnChange = ({ currentTarget }) => {
    const property = currentTarget.getAttribute("name");
    const { bankAccountInformation } = this.state;
    bankAccountInformation[property] = currentTarget.value;
    this.setState({ bankAccountInformation });
  };

  render() {
    const { bankAccountInformation, loadedData } = this.state;

    return (
      <div className="edit-form">
        <h1 className="text-center">Dane konta bankowego</h1>

        {!loadedData ? (
          <Spinner />
        ) : (
          <div>
            <MDBRow>
              <MDBCol md="12" className="mb-12">
                <label htmlFor="txtName" className="gray-text">
                  Nazwa
                </label>
                <input
                  type="text"
                  onChange={this.handleInputOnChange}
                  className="form-control form-control-sm"
                  value={bankAccountInformation.name}
                  id="txtName"
                  name="name"
                  required
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="12" className="mb-12">
                <label htmlFor="txtNumber" className="gray-text">
                  Numer konta
                </label>
                <input
                  type="text"
                  onChange={this.handleInputOnChange}
                  className="form-control form-control-sm"
                  value={bankAccountInformation.number}
                  id="txtNumber"
                  name="number"
                  required
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="12" className="mb-12">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bankAccountInformation.isActive}
                      onChange={this.handleCheckBoxChange}
                      name="isActive"
                      color="primary"
                    />
                  }
                  label="Aktywne"
                />
              </MDBCol>
            </MDBRow>
            <div className="text-center">
              <button className="btn btn-white" onClick={this.handleCancel}>
                Odrzuć
              </button>
              <button className="btn btn-primary" onClick={this.handleSave}>
                Zapisz
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(BankAccountEdit);
