import React, { useState } from "react";
import SummaryTable from "../../table/summaryTable";
import AddDotationButton from "./addDotation";

const DotationTable = ({ items }) => {
  const columns = [
    {
      header: (
        <span>
          Data / <br /> Numer
        </span>
      ),
      field: "dotationWithNumber",
    },
    {
      header: (
        <span>
          Państwo / <br /> Miasto
        </span>
      ),
      field: "countryWithCity",
    },
    {
      header: "Adresat",
      field: "dotationPersonName",
    },
    {
      header: (
        <span>
          Kwota PLN / <br /> Kwota (waluta)
        </span>
      ),
      field: "amountWithCurrency",
    },
    {
      header: "Subkonto",
      field: "projectName",
    },
    { header: "Cel", field: "purpose", width: 200 },
    {
      header: "Termin rozliczenia",
      field: "settlementDate",
      type: "date",
      orderType: "date",
    },
    {
      header: "Data skanu",
      field: "documentScanDate",
      type: "date",
      orderType: "date",
    },
    {
      header: "Data oryginału",
      field: "documentOriginalDate",
      type: "date",
      orderType: "date",
    },
    {
      header: "Dok. fotograficzna",
      field: "hasPhotographicDocumentation",
      type: "bool",
    },
    { header: "Uwagi", field: "description" },
    { header: "", field: "actions" },
  ];

  return (
    <>
      <div className="d-flex justify-content-between">
        <h3 className="text-center pt-2">Lista wpłat</h3>
        <AddDotationButton />
      </div>
      <SummaryTable columns={columns} data={items} />
    </>
  );
};

export default DotationTable;
