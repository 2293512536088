import React, { Component } from 'react';
import { MDBContainer } from 'mdbreact';
import BalanceTable from '../../features/balance/balanceTable';
import GrantSheetMenu from '../menu/menuNavBar';
import './balance.css'

class BalanceList extends Component {
    state = {}
    render() {
        return (
            <div>
                <GrantSheetMenu />

                <MDBContainer fluid>
                    <BalanceTable />
                </MDBContainer>



            </div>
        );
    }
}

export default BalanceList;