import React from 'react';
import { withRouter } from 'react-router-dom';
import { MDBBtn } from 'mdbreact';
import client_paths from '../../../constants/client_URL_paths';

const AddGrantButton = (props) => {
    const handleAddGrant = () => {
        props.history.push(`${client_paths.grantEditForm}/createGrant`);
    }

    return (
        <MDBBtn size="sm" onClick={() => handleAddGrant()}>Dodaj wpłatę</MDBBtn>
    );
}

export default withRouter(AddGrantButton);