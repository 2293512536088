import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Spinner from "../../common components/spinner/spinner";
import AddGrantButton from "../../features/grant/addGrant";
import grantService from "./grantService";
import client_path from "../../../constants/client_URL_paths";
import SummaryTable from "../../table/summaryTable";
import "./grant.css";

class GrantTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      columns: [
        { header: "Data wpłaty", field: "incomeDate" },
        { header: "Darczyńca", field: "donorDisplayMember" },
        {
          header: "Kwota wpłaty",
          field: "incomeAmount",
          isSummary: true,
          type: "currency",
        },
        { header: "Subkonto", field: "projectName" },
        { header: "Konto bankowe", field: "bankAccountName" },
        { header: "Uwagi", field: "description" },
        { header: "", field: "actions" },
      ],
      isLoaded: false,
    };

    this.searchGrantsCallback = this.searchGrantsCallback.bind(this);
  }

  componentDidMount() {
    var today = new Date();
    var dateFrom = new Date(today.getFullYear(), 0, 1);
    var dateTo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    this.searchGrants(dateFrom, dateTo);
  }

  searchGrants(from, to) {
    this.setState({ isLoaded: false });
    var request = {
      dateFrom: from,
      dateTo: to,
    };

    grantService.getGrants(request).then((grants) => {
      const data = grants.map((grant) => {
        return {
          id: grant.id,
          incomeDate: grant.incomeDate,
          projectName: grant.projectName,
          bankAccountName: grant.bankAccountName,
          donorDisplayMember: grant.donorDisplayMember,
          incomeAmount: grant.incomeAmount,
          description: grant.description,
          actions: (
            <span
              className="editButton"
              onClick={() => this.redirectToGrantEditForm(grant)}
            >
              Edytuj
            </span>
          ),
        };
      });

      this.setState({ data, isLoaded: true });
    });
  }

  searchGrantsCallback(from, to) {
    var dateFrom = new Date(from);
    var dateTo = new Date(to);
    this.searchGrants(dateFrom, dateTo);
  }

  redirectToGrantEditForm(grant) {
    this.props.history.push(`${client_path.grantEditForm}/${grant.id}`);
  }

  render() {
    const { columns, data, isLoaded } = this.state;
    var renderedPart = !isLoaded ? (
      <Spinner />
    ) : (
      <div>
        <div className="d-flex justify-content-between">
          <h3 className="text-center pt-2">Lista wpłat</h3>
          <AddGrantButton />
        </div>
        <SummaryTable
          columns={columns}
          searchItemCallback={this.searchGrantsCallback}
          showDateRangeSearch={true}
          data={data}
        />
      </div>
    );

    return <>{renderedPart}</>;
  }
}

export default withRouter(GrantTable);
