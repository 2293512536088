import { get } from "lodash";
import { MDBInput } from 'mdbreact';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from 'react-router-dom';
import client_path from '../../../constants/client_URL_paths';
import Spinner from '../../common components/spinner/spinner';
import "./project.css";
import dictionary_service from '../../../common-services/dictionaryService';
import projectService from "./projectService";

class ProjectCostEdit extends Component {

    state = {
        costInfo: {
            costDate: new Date(),
            projectId: "",
            bankAccountId: "",
            amount: 0,
            documentNumber: "",
            description: ""
        },

        projectSuggestions: [],
        bankAccountSuggestions: [],

        inputs: [
            { label: "Kwota", property: "amount" },
            { label: "Nr VAT/rachunku", property: "documentNumber" }
        ],

        loadedData: false
    }


    async componentDidMount() {
        try {

            const projectSuggestionItems = await dictionary_service.getProjectSuggestions();
            const bankAccountSuggestionItems = await dictionary_service.getBankAccountSuggestions();

            const costId = this.props.match.params.costId;
            if (costId === "createCost") {
                this.setState(
                    {
                        loadedData: true,
                        projectSuggestions: projectSuggestionItems,
                        bankAccountSuggestions: bankAccountSuggestionItems
                    })
                return
            }

            const foundCost = await projectService.getProjectCostById(costId)
            foundCost.costDate = new Date(foundCost.costDateValue);
            this.setState(
                {
                    costInfo: foundCost,
                    loadedData: true,
                    projectSuggestions: projectSuggestionItems,
                    bankAccountSuggestions: bankAccountSuggestionItems,
                })
        }
        catch (ex) {
            throw Error(ex)
        }
    }

    handleCancel = () => {
        this.props.history.push(`${client_path.projectBalanceList}/${this.props.match.params.projectId}`);
    }

    handleSave = async () => {
        try {
            const { costInfo } = this.state;
            const costId = this.props.match.params.costId;
            costInfo.projectId = this.props.match.params.projectId;

            if (costId === "createCost") {
                console.log(costInfo);
                await projectService.createProjectCost(costInfo);
            }
            else {
                await projectService.updateProjectCost(costInfo);
            }

            this.props.history.push(`${client_path.projectBalanceList}/${this.props.match.params.projectId}`);
        }
        catch (ex) {
            console.log('error', ex)
        }
    }

    handleInputOnChange = ({ currentTarget }) => {
        const property = currentTarget.getAttribute('data-name');
        const { costInfo } = this.state;
        costInfo[property] = currentTarget.value;
        this.setState({ costInfo })
    }

    onChange = date => {
        const { costInfo } = this.state;
        costInfo.costDate = date;
        this.setState({ costInfo })
    }

    //onProjectChange = (event) => {
    //    const { costInfo } = this.state;
    //    costInfo.projectId = event.target.value;
    //    this.setState({ costInfo })
    //}

    onBankAccountChange = (event) => {
        const { costInfo } = this.state;
        costInfo.bankAccountId = event.target.value;
        this.setState({ costInfo })
    }

    render() {
        const {
            bankAccountSuggestions,
            costInfo,
            inputs,
            loadedData
        } = this.state;

        return (
            <div className="editForm">
                <h1 className="text-center">Koszt</h1>
                {!loadedData ? <Spinner /> : <div className="edit-form__input-box">
                    <div>
                        Data kosztu:
                          <div>
                            <DatePicker
                                onChange={this.onChange}
                                selected={costInfo.costDate}>
                            </DatePicker>
                        </div>
                    </div>
                    <div>
                        Konto:
                        <select className="browser-default custom-select" onChange={this.onBankAccountChange} value={costInfo.bankAccountId}>
                            {bankAccountSuggestions.map(bankAccount => {
                                return (
                                    <option value={bankAccount.id} key={bankAccount.id}>{bankAccount.displayMember}</option>
                                )
                            })}
                        </select>

                    </div>
                    <div>
                        <MDBInput
                            label={inputs[0].label}
                            value={get(costInfo, inputs[0].property)}
                            data-name={inputs[0].property}
                            onChange={this.handleInputOnChange}
                        />
                    </div>
                    <div>
                        <MDBInput
                            label={inputs[1].label}
                            value={get(costInfo, inputs[1].property)}
                            data-name={inputs[1].property}
                            onChange={this.handleInputOnChange}
                        />
                    </div>
                    <MDBInput
                        type="textarea"
                        label="Uwagi"
                        rows="2"
                        value={costInfo.description}
                        data-name="description"
                        onChange={this.handleInputOnChange}
                    />
                    <div className="text-center">
                        <button
                            className="btn btn-white"
                            onClick={this.handleCancel}
                        >Odrzuć
                            </button>
                        <button
                            className="btn btn-primary"
                            onClick={this.handleSave}
                        >Zapisz
                            </button>
                    </div>

                </div>}

            </div>
        );
    }
}

export default withRouter(ProjectCostEdit);
