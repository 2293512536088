import httpService from "../../../common-services/httpService";
import server_path from "../../../constants/server_URL_paths";

export async function getProjects() {
  const { data } = await httpService.get(server_path.projects.getprojects);
  return data;
}

export async function getProjectsByRange(request) {
  const { data } = await httpService.post(
    `${server_path.projects.getProjectsByRange}`,
    request
  );
  return data;
}

export async function getProjectById(projectId) {
  const { data: project } = await httpService.get(
    `${server_path.projects.getProjectById}?projectId=${projectId}`
  );
  return project;
}

export async function updateProject(project) {
  const { data: foundProject } = await httpService.put(
    `${server_path.projects.updateProject}`,
    project
  );
  return foundProject;
}

export async function createProject(project) {
  const { data: foundProject } = await httpService.post(
    `${server_path.projects.createProject}`,
    project
  );
  return foundProject;
}

export async function deleteProjectCost(costId) {
  const { data: foundProject } = await httpService.delete(
    `${server_path.projects.deleteProjectCost}?costId=${costId}`
  );
  return foundProject;
}

export async function getProjectCosts(projectId) {
  const { data } = await httpService.get(
    `${server_path.projects.getProjectCosts}?projectId=${projectId}`
  );
  return data;
}

export async function getProjectGrants(projectId) {
  const { data } = await httpService.get(
    `${server_path.projects.getProjectGrants}?projectId=${projectId}`
  );
  return data;
}

export async function getQuarterGrants(projectId) {
  let currentDate = new Date();
  const { data } = await httpService.get(
    `${
      server_path.projects.getQuarterGrants
    }?projectId=${projectId}&year=${currentDate.getFullYear()}`
  );
  return data;
}

export async function getProjectCostById(costId) {
  const { data: cost } = await httpService.get(
    `${server_path.projects.getProjectCostById}?costId=${costId}`
  );
  return cost;
}

export async function updateProjectCost(cost) {
  const { data: foundCost } = await httpService.put(
    `${server_path.projects.updateProjectCost}`,
    cost
  );
  return foundCost;
}

export async function createProjectCost(cost) {
  const { data: foundCost } = await httpService.post(
    `${server_path.projects.createProjectCost}`,
    cost
  );
  return foundCost;
}

export async function getProjectLightModel() {
  const { data: projects } = await httpService.get(
    "/project/getProjectLightModel"
  );
  return projects;
}

export default {
  getProjects,
  getProjectById,
  getProjectCosts,
  getProjectGrants,
  updateProject,
  createProject,
  deleteProjectCost,
  getProjectCostById,
  updateProjectCost,
  createProjectCost,
  getQuarterGrants,
  getProjectsByRange,
  getProjectLightModel,
};
