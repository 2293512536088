import React from 'react';
import { MDBJumbotron } from "mdbreact";
import EditForm from '../../features/project/projectEdit';
import './projectEditForm.css';

const ProjectEditForm = () => {
    return (
        <div className="projectEditForm-box p-5">
            <MDBJumbotron style={{ width: "800px" }}>
                <EditForm />
            </MDBJumbotron>
        </div>

    );
}

export default ProjectEditForm;