import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import Spinner from '../../common components/spinner/spinner';
import grantService from '../grant/grantService';
import './donorTable.css';
class DonorDetailTable extends Component {
    state = {
        dateFrom: "",
        dateTo: "",
        donorName: "",
        dataToshow: [],
        columns: [
            { label: "Lp.", field: 'index', sort: 'asc', width: 60 },
            { label: "Data", field: 'incomeDate', sort: 'asc', width: 120 },
            { label: "Subkonto", field: 'projectName', sort: 'asc', width: 200 },
            { label: "Kwota", field: 'incomeAmount', sort: 'asc', width: 120 },
            { label: "Opis", field: 'description', sort: 'asc' }
        ],
        isLoaded: false
    }

    async componentDidMount() {
        var donorId = this.props.match.params.id;
        var donorFullName = "";
        const grants = await grantService.getDonorGrants(donorId);

        const dataToshow = grants.map((grant, index) => {
            donorFullName = `${grant.donorFirstName} ${grant.donorLastName}`
            return {
                index: index + 1,
                incomeDate: grant.incomeDate,
                projectName: grant.projectName,
                incomeAmount: grant.incomeAmount,
                description: grant.description
            }
        })

        this.setState({ dataToshow, isLoaded: true, donorName: donorFullName })
    }

    render() {
        const { columns, dataToshow, isLoaded, donorName } = this.state;
        const data = {
            columns,
            rows: dataToshow
        }

        var renderedPart = !isLoaded ? <Spinner /> :
            <div>


                <h4 className="titleHeader">Wpłaty darczyńcy <strong>{donorName}</strong> </h4>

                <MDBDataTable
                    scrollY
                    maxHeight="65vh"
                    entries={100}
                    bordered
                    small
                    data={data}
                    hover />
            </div>
        return (
            <>
                {renderedPart}
            </>
        );
    }
}

export default withRouter(DonorDetailTable);
