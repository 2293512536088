import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SummaryTable from '../../table/summaryTable'
import Spinner from '../../common components/spinner/spinner';
import projectService from "./projectService";
import client_path from '../../../constants/client_URL_paths';
import './project.css';

class ProjectCostDetail extends Component {
    state = {
        data: [],
        columns: [
            { header: "Data", field: 'costDate', type: 'date', orderType: 'date'  },
            { header: "Nr rachunku / dotacji", field: 'documentNumber' },
            { header: "Kwota", field: 'amount', isSummary: true, type: 'currency' },
            { header: "Rodzaj kosztu", field: 'costTypeName' },
            { header: "Opis", field: 'description'},
            { header: "Uwagi", field: 'note' }
        ],

        isLoaded: false
    }

    async componentDidMount() {
        var projectId = this.props.projectId;
        var items = await projectService.getProjectCosts(projectId);

        const data = items.map((cost, index) => {
            return {
                id: cost.id,
                costDate: cost.costDate,
                documentNumber: cost.documentNumber,
                amount: cost.amount,
                costTypeName: cost.costTypeName,
                description: cost.description,
                note: cost.note
            }
        })

        this.setState({ data, isLoaded: true })

    }

    redirectToProjectCostEdit(cost) {
        this.props.history.push(`${client_path.projectCostEditForm}/${cost.id}/${this.props.projectId}`)
    }

    deleteCost(costId) {
        projectService.deleteProjectCost(costId);
        window.location.reload();
    }

    render() {
        const { columns, data, isLoaded } = this.state;

        var renderedPart = !isLoaded ? <Spinner /> :
            <div>

                <SummaryTable
                   columns={columns}
                    defaultOrderBy="costDate"
                    defaultOrder="desc"
                    data={data} 
               />
            </div>

        return (
            <>
                {renderedPart}
            </>
        );
    }
}

export default withRouter(ProjectCostDetail);
