import React, { Component } from "react";
import bankAccountService from "./bankAccountService";
import { withRouter } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import AddBankAccountButton from "../../features/bankAccount/addBankAccount";
import Spinner from "../../common components/spinner/spinner";
import client_path from "../../../constants/client_URL_paths";
import "./bankAccount.css";

class BankAccountTable extends Component {
  state = {
    dataToShow: [],
    columns: [
      { label: "Nazwa konta", field: "name", sort: "asc", width: 385 },
      { label: "Numer konta", field: "number", sort: "asc", width: 385 },
      {
        label: "Saldo początkowe",
        field: "startBalance",
        sort: "asc",
        width: 300,
      },
      { label: "Główne", field: "isRoot", sort: "asc", width: 100 },
      { label: "Gotówka", field: "isCash", sort: "asc", width: 100 },
      { label: "Aktywne", field: "isActive", sort: "asc", width: 100 },
      { label: "", field: "actions", sort: false, width: 50 },
    ],
    isLoaded: false,
  };

  async componentDidMount() {
    const data = await bankAccountService.getBankAccounts();
    const dataToShow = data.map((bankAccount, index) => {
      return {
        index: index + 1,
        name: bankAccount.name,
        number: bankAccount.number,
        startBalance: bankAccount.startBalance,
        isRoot: (
          <div className="checkbox d-flex justify-content-center">
            {bankAccount.isRoot && (
              <span className="cr">
                <i className="cr-icon glyphicon glyphicon-ok">&#10003;</i>
              </span>
            )}
            {!bankAccount.isRoot && (
              <span className="cr">
                <i className="cr-icon glyphicon glyphicon-ok"></i>
              </span>
            )}
          </div>
        ),
        isCash: (
          <div className="checkbox d-flex justify-content-center">
            {bankAccount.isCash && (
              <span className="cr">
                <i className="cr-icon glyphicon glyphicon-ok">&#10003;</i>
              </span>
            )}
            {!bankAccount.isCash && (
              <span className="cr">
                <i className="cr-icon glyphicon glyphicon-ok"></i>
              </span>
            )}
          </div>
        ),
        isActive: (
          <div className="checkbox d-flex justify-content-center">
            {bankAccount.isActive && (
              <span className="cr">
                <i className="cr-icon glyphicon glyphicon-ok">&#10003;</i>
              </span>
            )}
            {!bankAccount.isActive && (
              <span className="cr">
                <i className="cr-icon glyphicon glyphicon-ok"></i>
              </span>
            )}
          </div>
        ),
        actions: (
          <span
            className="editButton"
            onClick={() => this.redirectToBankAccountEditForm(bankAccount)}
          >
            Edytuj
          </span>
        ),
      };
    });

    this.setState({ dataToShow, isLoaded: true });
  }

  redirectToBankAccountEditForm(bankAccount) {
    this.props.history.push(
      `${client_path.bankAccountEditForm}/${bankAccount.id}`
    );
  }

  render() {
    const { columns, dataToShow, isLoaded } = this.state;
    const data = {
      columns,
      rows: dataToShow,
    };

    var renderedPart = !isLoaded ? (
      <Spinner />
    ) : (
      <div>
        <div className="d-flex justify-content-between">
          <h3 className="text-center pt-2">Lista rachunków bankowych</h3>
          <AddBankAccountButton />
        </div>
        <MDBDataTable
          scrollY
          maxHeight="65vh"
          entries={100}
          bordered
          small
          data={data}
          hover
        />
      </div>
    );

    return <>{renderedPart}</>;
  }
}

export default withRouter(BankAccountTable);
