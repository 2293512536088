import React from 'react';
import { withRouter } from 'react-router-dom';
import { MDBBtn } from 'mdbreact';
import client_paths from '../../../constants/client_URL_paths';

const AddNewCostButton = (props) => {
    const handleAddNewCost = () => {
        props.history.push(`${client_paths.costEditForm}/createCost`);
    }

    return (
        <MDBBtn size="sm" onClick={() => handleAddNewCost()}>Dodaj koszt</MDBBtn>
    );
}

export default withRouter(AddNewCostButton);