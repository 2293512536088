import httpService from "../../../common-services/httpService";
import server_path from '../../../constants/server_URL_paths';

export async function getDonors() {
    const { data } = await httpService.get(server_path.donor.getDonors);
    return data;
}

export async function deleteDonor(donorId) {
    const { data: result } = await httpService.delete(`${server_path.donor.deleteDonor}?donorId=${donorId}`);
    return result;
}

export default {
    getDonors,
    deleteDonor
}