import httpService from "../../../common-services/httpService";
import server_path from '../../../constants/server_URL_paths';

export async function getBankAccounts() {
    const { data } = await httpService.get(server_path.bankAccount.getBankAccounts);
    return data;
}

export async function getBankAccountById(bankAccountId) {
    const { data: bankAccount } = await httpService.get(`${server_path.bankAccount.getBankAccount}?bankAccountId=${bankAccountId}`);
    return bankAccount;
}

export async function updateBankAccount(bankAccount) {
    const { data: foundBankAccount } = await httpService.put(`${server_path.bankAccount.updateBankAccount}`, bankAccount);
    return foundBankAccount;
}

export async function createBankAccount(bankAccount) {
    const { data: foundBankAccount } = await httpService.post(`${server_path.bankAccount.createBankAccount}`, bankAccount);
    return foundBankAccount;
}

export async function getDefaultBankAccount() {
    const { data: bankAccount } = await httpService.get(`${server_path.bankAccount.getDefaultBankAccount}`);
    return bankAccount;
}

export default {
    getBankAccounts,
    getBankAccountById,
    updateBankAccount,
    createBankAccount,
    getDefaultBankAccount
}
