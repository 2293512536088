import React from 'react';
import { MDBJumbotron } from "mdbreact";
import EditForm from '../../features/cost/costEdit';
import './cost.css';

const GrantEditForm = () => {
    return (
        <div className="costEditForm-box p-5">
            <MDBJumbotron style={{ width: "800px" }}>
                <EditForm />
            </MDBJumbotron>
        </div>

    );
}

export default GrantEditForm;