import React from 'react';
import { MDBJumbotron } from "mdbreact";
import EditForm from '../../features/editForm/editForm';
import './donorEditForm.css';

const DonorEditForm = () => {
    return (
        <div className="donorEditForm-box p-5">
            <MDBJumbotron style={{ width: "800px" }}>
                <EditForm />
            </MDBJumbotron>
        </div>

    );
}

export default DonorEditForm;