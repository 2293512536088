import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MDBRow, MDBCol } from "mdbreact";
import Spinner from "../../common components/spinner/spinner";
import editForm_service from "./projectService";
import client_path from "../../../constants/client_URL_paths";
import dateFnsFormat from "date-fns/format";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import DayPickerInput from "react-day-picker/DayPickerInput";

import "./project.css";

class ProjectEdit extends Component {
  state = {
    projectInformation: {
      name: "",
      description: "",
      startBalance: 0,
      percentage: 0,
      secondPercentage: 0,
      totalValue: 0,
      isWebSiteVisible: false,

      webSiteProjectStartDate: null,
      webSiteProjectTitle: "",
      webSiteProjectUrl: "",
      webSiteProjectImageUrl: "",
      isActive: true,
    },

    loadedData: false,
  };

  handleCheckBoxChange = (event) => {
    const { projectInformation } = this.state;
    projectInformation.isActive = event.target.checked;

    this.setState({ projectInformation });
  };

  handleTextFieldOnChange = ({ currentTarget }) => {
    const { projectInformation } = this.state;
    projectInformation.webSiteProjectTitle = currentTarget.value;

    this.setState({ projectInformation });
  };

  handleTextFieldProjectUrlOnChange = ({ currentTarget }) => {
    const { projectInformation } = this.state;
    projectInformation.webSiteProjectUrl = currentTarget.value;

    this.setState({ projectInformation });
  };

  handleTextFieldProjectImageUrlOnChange = ({ currentTarget }) => {
    const { projectInformation } = this.state;
    projectInformation.webSiteProjectImageUrl = currentTarget.value;

    this.setState({ projectInformation });
  };

  async componentDidMount() {
    try {
      const projectId = this.props.match.params.id;
      if (projectId === "createProject") {
        this.setState({ loadedData: true });
        return;
      }

      const foundProject = await editForm_service.getProjectById(projectId);
      if (foundProject.webSiteProjectStartDate) {
        var reversDate = foundProject.webSiteProjectStartDate
          .split(".")
          .reverse()
          .join("-");
        foundProject.webSiteProjectStartDate = new Date(reversDate);
      }

      this.setState({ projectInformation: foundProject, loadedData: true });
    } catch (ex) {
      throw Error(ex);
    }
  }

  handleCancel = () => {
    this.props.history.push(client_path.projectList);
  };

  handleInputOnChange = ({ currentTarget }) => {
    const property = currentTarget.getAttribute("data-name");
    const { projectInformation } = this.state;
    projectInformation[property] = currentTarget.value;

    this.setState({ projectInformation });
  };

  submitHanlder = (event) => {
    event.target.className += " was-validated";
    event.preventDefault();

    if (this.isValid()) {
      try {
        const projectId = this.props.match.params.id;
        const { projectInformation, isWebSiteProjectChecked } = this.state;
        projectInformation.isWebSiteVisible = isWebSiteProjectChecked;

        if (projectId === "createProject") {
          editForm_service.createProject(projectInformation);
        } else {
          editForm_service.updateProject(projectInformation);
        }

        this.props.history.push(client_path.projectList);
      } catch (ex) {
        console.log("error", ex);
      }
    }
  };

  formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  onDayChange = (date) => {
    const { projectInformation } = this.state;
    projectInformation.webSiteProjectStartDate = date;
    this.setState({ projectInformation });
  };

  isValid() {
    const { projectInformation } = this.state;
    if (projectInformation.name === "" || projectInformation.name === null) {
      return false;
    }

    projectInformation.startBalance = projectInformation.startBalance
      .toString()
      .replace(",", ".");

    return true;
  }

  render() {
    const { projectInformation, loadedData } = this.state;
    const {
      webSiteProjectTitle,
      webSiteProjectUrl,
      webSiteProjectImageUrl,
      webSiteProjectStartDate,
    } = projectInformation;
    const dateFormat = "dd.MM.yyyy";

    return (
      <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHanlder.bind(this)}
          noValidate
        >
          <h1 className="text-center">Subkonto</h1>
          {!loadedData ? (
            <Spinner />
          ) : (
            <div>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="txtName" className="gray-text">
                    Nazwa
                  </label>
                  <div>
                    <input
                      type="text"
                      onChange={this.handleInputOnChange}
                      className="form-control form-control-sm"
                      value={projectInformation.name}
                      id="txtName"
                      data-name="name"
                      required
                    />
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <label className="gray-text" htmlFor="cbxPerentage">
                    Procent (prowizja 1)
                  </label>
                  <select
                    id="cbxPerentage"
                    value={projectInformation.percentage}
                    onChange={this.handleInputOnChange}
                    className="browser-default custom-select"
                    data-name="percentage"
                  >
                    <option value="0">0 %</option>
                    <option value="10">10 %</option>
                    <option value="15">15 %</option>
                  </select>
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <label className="gray-text" htmlFor="cbxPerentage">
                    Procent (prowizja 2)
                  </label>
                  <select
                    id="cbxPerentage"
                    value={projectInformation.secondPercentage}
                    onChange={this.handleInputOnChange}
                    className="browser-default custom-select"
                    data-name="secondPercentage"
                  >
                    <option value="0">0 %</option>
                    <option value="5">5 %</option>
                  </select>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="txtStartBalance" className="gray-text">
                    Saldo początkowe
                  </label>
                  <div>
                    <input
                      type="number"
                      step="0.01"
                      min="0"
                      onChange={this.handleInputOnChange}
                      className="form-control form-control-sm"
                      value={projectInformation.startBalance}
                      id="txtStartBalance"
                      data-name="startBalance"
                      required
                    />
                  </div>
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <label htmlFor="txtTotalValue" className="gray-text">
                    Wartość projektu
                  </label>
                  <div>
                    <input
                      type="number"
                      step="0.01"
                      min="0"
                      onChange={this.handleInputOnChange}
                      className="form-control form-control-sm"
                      value={projectInformation.totalValue}
                      id="txtTotalValue"
                      data-name="totalValue"
                    />
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="txtDescription" className="gray-text">
                    Opis
                  </label>
                  <textarea
                    rows="3"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={projectInformation.description}
                    id="txtDescription"
                    data-name="description"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-12">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={projectInformation.isActive}
                        onChange={this.handleCheckBoxChange}
                        name="isActive"
                        color="primary"
                      />
                    }
                    label="Aktywne"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="dtDateInput" className="gray-text">
                    Data rozpoczęcia zbiórki
                  </label>
                  <div>
                    <DayPickerInput
                      name="incomeDate"
                      format={dateFormat}
                      formatDate={this.formatDate}
                      onDayChange={this.onDayChange}
                      value={webSiteProjectStartDate}
                      className="form-control form-control-sm"
                      id="dtDateInput"
                      required
                    />
                  </div>
                </MDBCol>
              </MDBRow>
              {/* <MDBRow>
                            <MDBCol md="6" className="mb-3">
                                <FormControlLabel
                                        control={
                                        <Checkbox
                                            checked={isWebSiteProjectChecked}
                                            onChange={this.handleCheckBoxChange}
                                            name="chkIsWebsite"
                                            color="primary"
                                        />
                                        }
                                        label="Czy subkonto widoczne na www ?"
                                    />
                            </MDBCol>
                        </MDBRow> */}
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <TextField
                    fullWidth
                    value={webSiteProjectTitle}
                    onChange={this.handleTextFieldOnChange}
                    label="Subkonto (nazwa www)"
                  />
                </MDBCol>
                <MDBCol md="6" className="mb-3">
                  <TextField
                    fullWidth
                    value={webSiteProjectUrl}
                    onChange={this.handleTextFieldProjectUrlOnChange}
                    label="Adres url projektu"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <TextField
                    fullWidth
                    value={webSiteProjectImageUrl}
                    onChange={this.handleTextFieldProjectImageUrlOnChange}
                    label="Link do zdjęcia"
                  />
                </MDBCol>
              </MDBRow>

              <div className="text-center">
                <button className="btn btn-white" onClick={this.handleCancel}>
                  Odrzuć
                </button>
                <button className="btn btn-primary" type="submit">
                  Zapisz
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default withRouter(ProjectEdit);
