import React from 'react';
import { MDBJumbotron } from "mdbreact";
import Transfer from '../components/transfer-edit';
import '../../../../assets/css/fda.css';

const TransferEditPage = () => {
    return (
        <div className="edit-container p-5">
            <MDBJumbotron style={{ width: "800px" }}>
                <Transfer />
            </MDBJumbotron>
        </div>

    );
}

export default TransferEditPage;