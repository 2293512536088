import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Joi from 'joi-browser';
import { MDBInput } from "mdbreact";
import client_paths from '../../../constants/client_URL_paths';
import Spinner from "../../common components/spinner/spinner";
import authService from "../../../common-services/authService";
import httpService from "../../../common-services/httpService";
import utilsService from '../../../common-services/utils';
import './loginForm.css';

class LoginForm extends Component {
    state = {
        user: {
            login: "",
            password: ""
        },
        errorResponse: {},
        isWaitingForSerwerResponse: false,

    }
    schema = {
        login: Joi.string().min(1).required().error(() => { return { message: "Za krótki login." }; }),
        password: Joi.string().min(5).required().error(() => { return { message: "Za krótkie hasło." }; })
    };

    handleRemoveErrorMessage = () => {
        const { errorResponse } = this.state;
        const self = this;
        setTimeout(function(){
            delete errorResponse.message;
            self.setState({errorResponse})
        }, 3000)
    }
    handleInputOnChange = ({ currentTarget }) => {
        const property = currentTarget.getAttribute('data-name');
        const { user } = this.state;
        user[property] = currentTarget.value
        this.setState({ user })
    };

    validateUser(user) {
        const { error } = Joi.validate(user, this.schema);
        return error ? error.details[0].message : null;
    }

    handleLogin = async () => {
        const { login, password } = this.state.user;
        const error = this.validateUser({ login, password });
        if (error) { console.log('error: ', error) }

        this.runLoader(true);
        try {
            const response = await authService.login(login.trim().toLowerCase(), password);
            const token = response.token;

            authService.setTokenToLocalStorage(token);
            httpService.setHeader_token(token)

            this.props.history.push(client_paths.donorList);
        }
        catch (ex) {
            if (ex.response && (ex.response.status === 400 || ex.response.status === 403)) {
                const { errorResponse } = this.state;
                errorResponse.message = ex.response.data
                this.handleRemoveErrorMessage();
                this.setState({errorResponse});
            }

            this.runLoader(false);
        }

    }

    runLoader(mode) {
        let { isWaitingForSerwerResponse } = this.state;
        isWaitingForSerwerResponse = mode;
        this.setState({ isWaitingForSerwerResponse })
    }

    render() {
        const { isWaitingForSerwerResponse, errorResponse } = this.state;
        return (
            <div className="login-form">

                <h1 className="text-center">Podaj login i hasło</h1>
                <div className="login-form__input-box">
                    <div className="login-form__input-box_group">
                        <MDBInput
                            label="Login"
                            data-name="login"
                            autoFocus
                            onChange={this.handleInputOnChange}
                            onKeyPress={(target) => utilsService.runFunctionAfterPressEnter(target, this.handleLogin)}
                        />
                    </div>
                </div>
                <div className="login-form__input-box">
                    <div className="login-form__input-box_group">
                        <MDBInput
                            label="Hasło"
                            data-name="password"
                            type="password"
                            onChange={this.handleInputOnChange}
                            onKeyPress={(target) => utilsService.runFunctionAfterPressEnter(target, this.handleLogin)}
                        />
                    </div>
                </div>
                { errorResponse.message && (
                    <div className={"alert alert-danger"} role="alert">
                       {errorResponse.message}
                    </div>
                )}
                {isWaitingForSerwerResponse && <Spinner />}
                {!isWaitingForSerwerResponse &&
                    <button
                        className="btn btn-default"
                        onClick={() => this.handleLogin()}
                    >Zaloguj</button>
                }
            </div>
        );
    }
}

export default withRouter(LoginForm);