import httpService from "../common-services/httpService";
import server_path from "../constants/server_URL_paths";

export async function getDonorSuggestions() {
  const { data } = await httpService.get(
    server_path.dictionary.getDonorSuggestions
  );
  return data;
}

export async function getProjectSuggestions() {
  const { data } = await httpService.get(
    server_path.dictionary.getProjectSuggestions
  );
  return data;
}

export async function getBankAccountSuggestions() {
  const { data } = await httpService.get(
    server_path.dictionary.getBankAccountSuggestions
  );
  return data;
}

export async function getDefaultProject() {
  const { data } = await httpService.get(
    server_path.projects.getDefaultProject
  );
  return data;
}

export async function getGrantDefaultProject() {
  const { data } = await httpService.get(
    server_path.projects.getGrantDefaultProject
  );
  return data;
}

export async function getCostDefaultProject() {
  const { data } = await httpService.get(
    server_path.projects.getCostDefaultProject
  );
  return data;
}

export async function getDotationDefaultProject() {
  const { data } = await httpService.get(
    server_path.projects.getDotationDefaultProject
  );
  return data;
}

export default {
  getDonorSuggestions,
  getProjectSuggestions,
  getBankAccountSuggestions,
  getDefaultProject,
  getGrantDefaultProject,
  getCostDefaultProject,
  getDotationDefaultProject,
};
