import React, { Component } from "react";
import userService from "../../features/user/userService";
import "../../../../src/main.css";
import {
  MDBNavbar,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBIcon,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdown,
  MDBDropdownToggle,
} from "mdbreact";

class MenuNavBar extends Component {
  state = {
    isOpen: false,
    isLoaded: false,
    loggedUser: {
      isAdministrator: false,
      userName: "",
      id: "",
      isLogged: false,
    },
  };

  toggleCollapse = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  async componentDidMount() {
    var user = await userService.getLoggedUser();
    this.setState({ loggedUser: user, isLoaded: true });
  }

  logout() {
    userService.logout();
  }

  renderNavBar() {
    const { loggedUser } = this.state;
    var isAdmin = loggedUser.isAdministrator;
    var renderCost = !isAdmin ? (
      ""
    ) : (
      <MDBNavItem>
        <MDBNavLink to="/costList">Koszty |</MDBNavLink>
      </MDBNavItem>
    );

    var renderQuarterSettlement = !isAdmin ? (
      ""
    ) : (
      <MDBNavItem>
        <MDBNavLink to="/quarterSettlement">Rozliczenia kwartalne |</MDBNavLink>
      </MDBNavItem>
    );

    return (
      <div>
        <MDBNavbar color="default-color" dark expand="md">
          <MDBNavbarToggler onClick={this.toggleCollapse} />
          <MDBCollapse isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav left>
              <MDBNavItem>
                <MDBNavLink to="/grantList">Wpłaty |</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to="/donorList">Darczyńcy |</MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to="/projectList">Subkonta |</MDBNavLink>
              </MDBNavItem>
              {renderCost}
              <MDBNavItem>
                <MDBNavLink to="/bankAccountList">
                  Rachunki bankowe |
                </MDBNavLink>
              </MDBNavItem>
              {renderQuarterSettlement}
              <MDBNavItem>
                <MDBNavLink to="/transfers">Transfery wewnętrzne | </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to="/cash-report">Raport kasowy | </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to="/dotations">Dotacje </MDBNavLink>
              </MDBNavItem>
            </MDBNavbarNav>
            <MDBNavbarNav right>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span className="userName">
                      Witaj, {loggedUser.userName}{" "}
                    </span>
                    <MDBIcon icon="user" />
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className="dropdown-default">
                    <MDBDropdownItem href="!#" onClick={this.logout}>
                      Wyloguj
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </div>
    );
  }

  render() {
    const { isLoaded, loggedUser } = this.state;
    var renderPart = isLoaded && loggedUser.isLogged ? this.renderNavBar() : "";

    return <>{renderPart}</>;
  }
}

export default MenuNavBar;
