import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBRow, MDBCol } from 'mdbreact';
import "react-datepicker/dist/react-datepicker.css";
import dictionary_service from '../../../../common-services/dictionaryService';
import transferService from '../services/transferService';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import client_path from '../../../../constants/client_URL_paths';
import Spinner from '../../../common components/spinner/spinner';

class TransferEdit extends Component {
    state = {
        transfer: {
            id: '',
            transferDate: new Date(),
            sourceProjectId: '',
            targetProjectId: '',
            amount: 0
        },
        projects: [],
        isLoaded: false
    };

    async componentDidMount() {

        var projects = await dictionary_service.getProjectSuggestions();
        var defaultProject = await dictionary_service.getDefaultProject();

        const transferId = this.props.match.params.id;
        if(transferId === "createTransfer") {
            const { transfer } = this.state;
            transfer.targetProjectId = defaultProject.id;
            transfer.sourceProjectId = projects[0].id;
            this.setState({projects, transfer, isLoaded: true});
        }
        else {
            
            const transferToEdit = await transferService.getTransfer(transferId);
            var reversDate = transferToEdit.transferDate.split(".").reverse().join("-");
            transferToEdit.transferDate = new Date(reversDate);

            this.setState({projects, transfer: transferToEdit, isLoaded: true });
        }
    }

    submitHanlder = (event) => {
        event.preventDefault();
       
        if (this.isValid()) {
            try {
                const { transfer } = this.state;
                const transferId = this.props.match.params.id;

                if (transferId === "createTransfer") {
                    transfer.id = '00000000-0000-0000-0000-000000000000';
                    transferService.create(transfer).then(() => {
                        this.props.history.push(client_path.transferPage)
                    });
                }
                else {
                    transferService.update(transfer).then(() => {
                        this.props.history.push(client_path.transferPage)
                    });
                }

            }
            catch (ex) {
                console.log('error', ex)
            }
        }
    }

    isValid() {
        const { transfer } = this.state;

        if (transfer.amount === "" || transfer.amount === "0" || transfer.amount <= 0) {
            alert('Podaj kwotę większą od zera.')
            return false;
        }

        if (transfer.targetProjectId === transfer.sourceProjectId) {
            alert('Transfer wewnętrzny musi zostać wykonany na różne subkonta.')
            return false;
        }

        transfer.amount = transfer.amount.toString().replace(",", ".");
        return true;
    }

    handleOnSourceProjectChanged = (event) => {
        const { transfer } = this.state;
        transfer.sourceProjectId = event.target.value;
        this.setState({ transfer })
    }

    handleOnTargetProjectChanged = (event) => {
        const { transfer } = this.state;
        transfer.targetProjectId = event.target.value;
        this.setState({ transfer })
    }

    handleCancel = () => {
        this.props.history.push(client_path.transferPage)
    }

    onDayChange = date => {
        const { transfer } = this.state;
        transfer.transferDate = date;
        this.setState({ transfer })
    }

    handleInputOnChange = ({ currentTarget }) => {
        const property = currentTarget.getAttribute('name');
        const { transfer } = this.state;
        transfer[property] = currentTarget.value;
        this.setState({ transfer })
    }

    render() {
        const { transfer, projects, isLoaded } = this.state;
        const dateFormat = "dd.MM.yyyy";
 
        return(
            <div>
                <h1 className="text-center">Transfer wewnętrzny</h1>
                {!isLoaded ? <Spinner /> :
                <form
                    onSubmit={this.submitHanlder.bind(this)} 
                    className="needs-validation"
                    noValidate>
                    <div>
                        <MDBRow>
                            <MDBCol md="12" className="mb-3">
                                <label
                                    htmlFor="cbxSourceProjects"
                                    className="gray-text">
                                    Transfer z subkonta
                                </label>
                                <select
                                    id="cbxSourceProjects"
                                    className="browser-default custom-select"
                                    onChange={this.handleOnSourceProjectChanged}
                                    value={transfer.sourceProjectId}
                                    required>
                                    {projects.map(project => {
                                        return (
                                            <option value={project.id} key={project.id}>{project.displayMember}</option>
                                        )
                                    })}
                                </select>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="12" className="mb-3">
                                <label
                                    htmlFor="cbxTargetProjects"
                                    className="gray-text">
                                    Transfer na subkonto
                                </label>
                                <select
                                    id="cbxTargetProjects"
                                    className="browser-default custom-select"
                                    onChange={this.handleOnTargetProjectChanged}
                                    value={transfer.targetProjectId}
                                    required>
                                    {projects.map(project => {
                                        return (
                                            <option value={project.id} key={project.id}>{project.displayMember}</option>
                                        )
                                    })}
                                </select>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                        <MDBCol md="6" className="mb-3">
                                <label
                                    htmlFor="txtAmount"
                                    className="gray-text">
                                    Kwota
                                </label>
                                <input
                                    type="text"
                                    pattern="[0-9]+([\.,][0-9]+)?"
                                    onChange={this.handleInputOnChange}
                                    className="form-control form-control-sm"
                                    value={transfer.amount}
                                    id="txtAmount"
                                    name="amount"
                                    required />
                            </MDBCol>
                            <MDBCol md="6" className="mb-3">
                                <label
                                    htmlFor="dtDateInput"
                                    className="gray-text">
                                    Data transferu
                                </label>
                                <div>
                                    <DayPickerInput
                                        name="transferDate"
                                        format={dateFormat}
                                        formatDate={this.formatDate}
                                        onDayChange={this.onDayChange}
                                        value={transfer.transferDate}
                                        className="form-control form-control-sm"
                                        id="dtDateInput"
                                        required />
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <div className="text-center">
                            <button className="btn btn-white" onClick={this.handleCancel}>Odrzuć</button>
                            <button className="btn btn-primary" type="submit" >Zapisz</button>
                        </div>
                    </div>
                </form> }
            </div>
        )
    }
}

export default withRouter(TransferEdit);