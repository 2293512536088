export default {
  donorList: "/donorList",
  donorDetails: "/donorDetails",
  donorEditForm: "/donorEditForm",
  home: "/",
  loginPage: "/loginPage",
  bankAccountList: "/bankAccountList",
  bankAccountEditForm: "/bankAccountEditForm",
  projectList: "/projectList",
  projectEditForm: "/projectEditForm",
  projectCostEditForm: "/projectCostEditForm",
  grantList: "/grantList",
  grantEditForm: "/grantEditForm",
  costList: "/costList",
  costEditForm: "/costEditForm",
  projectBalanceList: "/projectBalanceList",
  balanceList: "/balanceList",
  balanceEditForm: "/balanceEditForm",
  balanceAnalysisList: "/balanceAnalysisList",
  quarterSettlement: "/quarterSettlement",
  noPermissions: "/no-permissions",
  transferPage: "/transfers",
  transferEditPage: "/transfer",
  cashReportList: "/cash-report",
  dotationList: "/dotations",
  dotationEdit: "/dotations/edit",
};
