import React, { useCallback, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

const SearchBar = ({searchPatternCallback}) => {
    const[filter, setFilter] = useState('');
    const delayedQuery = useCallback(_.debounce(q => searchPatternCallback(q), 500), []);

    const onFilterChanged = (e) => {
        const filterPattern = e.target.value;
        setFilter(filterPattern);
        delayedQuery(filterPattern);
    }

    return (
        <TextField 
            id="searchPattern" 
            label="szukaj..." 
            type="search" 
            value={filter}
            onChange={onFilterChanged}/>
    )
};

export default SearchBar;