import React from 'react';
import { MDBJumbotron } from "mdbreact";
import ProjectCostEdit from '../../features/project/projectCostEdit';
import './projectEditForm.css';

const ProjectCostEditForm = (props) => {
    return (
        <div className="projectEditForm-box p-5">
            <MDBJumbotron style={{ width: "800px" }}>
                <ProjectCostEdit projectId={props.projectId} />
            </MDBJumbotron>
        </div>

    );
}

export default ProjectCostEditForm;