import React, { useEffect, useState } from "react";
import { MDBContainer, MDBBtn } from "mdbreact";
import CashReportTable from "../../features/cash-report/cashReportTable";
import GrantSheetMenu from "../menu/menuNavBar";
import cashReportService from "./cash-report.service";

const CashReportList = () => {
  const months = [
    { name: "Styczeń", value: 0 },
    { name: "Luty", value: 1 },
    { name: "Marzec", value: 2 },
    { name: "Kwiecień", value: 3 },
    { name: "Maj", value: 4 },
    { name: "Czerwiec", value: 5 },
    { name: "Lipiec", value: 6 },
    { name: "Sierpień", value: 7 },
    { name: "Wrzesień", value: 8 },
    { name: "Październik", value: 9 },
    { name: "Listopad", value: 10 },
    { name: "Grudzień", value: 11 },
  ];

  const years = [];
  var date = new Date();
  var currentYear = date.getFullYear();
  years.push(currentYear);

  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();

  const [cashReport, setCashReport] = useState(null);
  const [reportDate, setReportDate] = useState();
  const [startBalance, setStartBalance] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [balance, setBalance] = useState(0);
  const [balanceWithIncomes, setBalanceWithIncomes] = useState(0);

  useEffect(() => {
    setSelectedYear(currentYear);

    const currentMonth = months.filter((x) => x.value === date.getMonth())[0];
    setSelectedMonth(currentMonth.value);
  }, []);

  function handleInputYear({ target }) {
    setSelectedYear(target.value);
  }

  function handleInputMonth({ target }) {
    setSelectedMonth(target.value);
  }

  async function exportToExcelCallback() {
    const details = cashReport.map((reportRow) => {
      return {
        id: reportRow.id,
        date: reportRow.date,
        incomeValue: reportRow.incomeValue ? reportRow.incomeValue : 0,
        costValue: reportRow.costValue ? reportRow.costValue : 0,
        documentNumber: reportRow.documentNumber,
        projectName: reportRow.projectName,
        description: reportRow.description,
      };
    });
    const request = {
      reportDate: reportDate,
      startBalance: startBalance,
      totalIncome: totalIncome,
      totalCost: totalCost,
      balanceWithIncomes: balanceWithIncomes,
      balance: balance,
      details: details,
    };

    await cashReportService.exportToExcel(request);
  }

  async function getCashReport() {
    const month = parseInt(selectedMonth) + 1;
    const daysInMonth = new Date(selectedYear, month, 0).getDate();
    const from = new Date(selectedYear, selectedMonth, 1);
    const to = new Date(selectedYear, selectedMonth, daysInMonth, 23, 59, 59);

    cashReportService.getCashReport(from, to);
    const dataSource = await cashReportService.getCashReport(from, to);
    const items = dataSource.details.map((reportRow) => {
      return {
        id: reportRow.id,
        date: reportRow.date,
        incomeValue: reportRow.incomeValue === 0 ? null : reportRow.incomeValue,
        costValue: reportRow.costValue === 0 ? null : reportRow.costValue,
        documentNumber: reportRow.documentNumber,
        projectName: reportRow.projectName,
        description: reportRow.description,
      };
    });
    setCashReport(items);
    setReportDate(dataSource.reportDate);
    setStartBalance(dataSource.startBalance);
    setBalance(dataSource.balance);
    setTotalIncome(dataSource.totalIncome);
    setTotalCost(dataSource.totalCost);
    setBalanceWithIncomes(dataSource.balanceWithIncomes);
  }

  return (
    <>
      <GrantSheetMenu />
      <MDBContainer fluid>
        <label htmlFor="cbxYears">Wybierz rok</label>
        <select
          className="browser-default custom-select"
          id="cbxYears"
          onChange={handleInputYear}
          value={selectedYear}
        >
          {years.map((year) => {
            return (
              <option value={year} key={year}>
                {year}
              </option>
            );
          })}
        </select>

        <label htmlFor="cbxMonths">Wybierz miesiąc</label>
        <select
          className="browser-default custom-select"
          id="cbxMonths"
          onChange={handleInputMonth}
          value={selectedMonth}
        >
          {months.map((month) => {
            return (
              <option value={month.value} key={month.value}>
                {month.name}
              </option>
            );
          })}
        </select>
        <MDBBtn color="warning" size="sm" onClick={getCashReport}>
          Pobierz raport kasowy
        </MDBBtn>
        {cashReport ? (
          <CashReportTable
            balance={balance}
            totalIncome={totalIncome}
            totalCost={totalCost}
            startBalance={startBalance}
            reportItems={cashReport}
            balanceWithIncomes={balanceWithIncomes}
            exportToExcelCallback={exportToExcelCallback}
          />
        ) : (
          <></>
        )}
      </MDBContainer>
    </>
  );
};

export default CashReportList;
