import React, { Component } from "react";
import {
  MDBContainer,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
} from "mdbreact";
import GrantSheetMenu from "../menu/menuNavBar";
import ProjectCostDetail from "../../features/project/projectCostDetail";
import ProjectGrantDetail from "../../features/project/projectGrantDetail";
import ProjectSummaryDetail from "../../features/project/projectSummaryDetail";
import projectService from "../../features/project/projectService";

import "./projectList.css";

class ProjectBalanceList extends Component {
  state = {
    activeItem: "1",
    projectName: "",
  };

  toggle = (tab) => (e) => {
    if (this.state.activeItem !== tab) {
      this.setState({ activeItem: tab });
    }
  };

  async componentDidMount() {
    var projectId = this.props.match.params.id;
    var project = await projectService.getProjectById(projectId);
    this.setState({ projectName: project.name });
  }

  render() {
    const { projectName } = this.state;

    return (
      <div>
        <GrantSheetMenu />
        <div className="header">
          <MDBContainer fluid>
            <div>
              <h5>
                Subkonto: <strong>{projectName}</strong>
              </h5>
            </div>
            <MDBNav className="nav-tabs mt-5">
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItem === "1"}
                  onClick={this.toggle("1")}
                  role="tab"
                >
                  Przychody subkonta
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItem === "2"}
                  onClick={this.toggle("2")}
                  role="tab"
                >
                  Koszty subkonta
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  link
                  to="#"
                  active={this.state.activeItem === "3"}
                  onClick={this.toggle("3")}
                  role="tab"
                >
                  Szczegóły
                </MDBNavLink>
              </MDBNavItem>
            </MDBNav>
            <MDBTabContent activeItem={this.state.activeItem}>
              <MDBTabPane tabId="1" role="tabpanel">
                <ProjectGrantDetail projectId={this.props.match.params.id} />
              </MDBTabPane>
              <MDBTabPane tabId="2" role="tabpanel">
                <ProjectCostDetail projectId={this.props.match.params.id} />
              </MDBTabPane>
              <MDBTabPane tabId="3" role="tabpanel">
                <ProjectSummaryDetail projectId={this.props.match.params.id} />
              </MDBTabPane>
            </MDBTabContent>
          </MDBContainer>
        </div>
      </div>
    );
  }
}

export default ProjectBalanceList;
