import React from 'react';
import { withRouter } from 'react-router-dom';
import { MDBBtn } from 'mdbreact';
import client_paths from '../../../constants/client_URL_paths';

const AddNewBankAccountButton = (props) => {
    const handleAddNewBankAccount = () => {
        props.history.push(`${client_paths.bankAccountEditForm}/createBankAccount`);
    }

    return (
        <MDBBtn size="sm" onClick={() => handleAddNewBankAccount()}>Dodaj konto bankowe</MDBBtn>
    );
}

export default withRouter(AddNewBankAccountButton);