import React, { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import TablePagination from "@material-ui/core/TablePagination";
import SearchBar from "../table/searchBar";
import SearchDateRange from "../table/search-date-range";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: 10,
  },
  paper: {
    width: "100%",
    marginTop: 20,
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const resolveContentCell = (column, rowItem) => {
  const formatter = new Intl.NumberFormat("pl-PL", {
    style: "currency",
    currency: "PLN",
    minimumFractionDigits: 2,
  });

  var value = rowItem[column.field];
  var cellWidth = column.width;
  var tableCell = !cellWidth ? (
    <TableCell>{value}</TableCell>
  ) : (
    <TableCell style={{ minWidth: cellWidth }}>{value}</TableCell>
  );

  var fontWeightValue = column.isBold ? "bold" : "normal";

  switch (column.type) {
    case "currency":
      tableCell = value ? (
        <TableCell align="right" style={{ fontWeight: fontWeightValue }}>
          {formatter.format(value)}
        </TableCell>
      ) : (
        <TableCell></TableCell>
      );
      break;
    case "percent":
      tableCell = <TableCell align="right">{value} %</TableCell>;
      break;
    case "bool":
      tableCell = (
        <TableCell>
          <Checkbox checked={value} />
        </TableCell>
      );
      break;
    default:
      break;
  }

  return tableCell;
};

const getSummary = (field, data, selected) => {
  let sum = 0;
  if (!data) {
    return "";
  }

  if (data.length === 0) {
    return sum.toFixed(2);
  }

  if (selected.length !== 0) {
    let selectedSummary = 0;
    for (let i = 0; i < selected.length; i++) {
      for (let index = 0; index < data.length; index++) {
        if (selected[i] === data[index].id) {
          selectedSummary = selectedSummary + data[index][field];
        }
      }
    }

    return selectedSummary.toFixed(2);
  }

  var obj = data.map((item) => item[field]).reduce((x, y) => x + y);

  if (obj) {
    return obj.toFixed(2);
  }

  return 0;
};

const resovleSummaryHeaderRow = (
  columns,
  data,
  selected,
  handleSelectAllClick
) => {
  var hasSummaryColumn = columns.some((x) => x.isSummary);
  var rowCount = data.length;
  var selectedRows = selected.length;

  return (
    <TableRow key={Date.now()} style={{ backgroundColor: "WhiteSmoke" }}>
      {hasSummaryColumn ? (
        <TableCell>
          <Checkbox
            checked={rowCount > 0 && selectedRows === rowCount}
            onChange={(event) => handleSelectAllClick(event, data)}
          />
        </TableCell>
      ) : null}
      {columns.map((column) =>
        column.isSummary ? (
          <TableCell align="right">
            {getSummary(column.field, data, selected)} zł{" "}
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )
      )}
    </TableRow>
  );
};

function convertToDate(dateString) {
  var splitDate = dateString.split(".");
  return new Date(splitDate[2], splitDate[1], splitDate[0]);
}

function descendingComparator(a, b, orderBy, orderType) {
  let value1 = a[orderBy];
  let value2 = b[orderBy];

  if (orderType === "date") {
    value1 = convertToDate(value1);
    value2 = convertToDate(value2);
  }

  if (value2 < value1) {
    return -1;
  }

  if (value2 > value1) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy, columns) {
  var orderType = "";
  for (let colIndex = 0; colIndex < columns.length; colIndex++) {
    let column = columns[colIndex];
    if (
      column.field !== undefined &&
      column.orderType !== undefined &&
      column.field === orderBy
    ) {
      orderType = column.orderType;
    }
  }

  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, orderType)
    : (a, b) => -descendingComparator(a, b, orderBy, orderType);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const SummaryTable = ({
  columns,
  data,
  defaultOrderBy,
  defaultOrder,
  searchItemCallback,
  showDateRangeSearch,
}) => {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState(defaultOrder);
  const [showDateRange] = useState(showDateRangeSearch);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [filter, setFilter] = useState("");

  const classes = useStyles();
  const searchCallback = useCallback((dateFrom, dateTo) =>
    searchItemCallback(dateFrom, dateTo)
  );
  const hasSummaryRow = columns.some((x) => x.isSummary === true);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event, items) => {
    if (event.target.checked) {
      const newSelecteds = items.map((item) => item.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const onSearchClick = (dateFrom, dateTo) => {
    if (searchItemCallback) {
      searchCallback(dateFrom, dateTo);
    }
  };

  const onfilterChanged = (text) => {
    setFilter(text);
  };

  const filterData = () => {
    if (filter === "") {
      return data;
    }

    return data.filter((item) => {
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        var fieldValue = item[columns[colIndex].field];

        if (fieldValue !== null && fieldValue !== undefined) {
          var itemValue = fieldValue.toString().toLowerCase();
          if (itemValue.includes(filter.toLowerCase())) {
            return true;
          }
        }
      }

      return false;
    });
  };

  const handleRequestSort = (field) => {
    const isAsc = orderBy === field && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(field);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      {showDateRange ? (
        <SearchDateRange searchDataCallback={onSearchClick} />
      ) : null}
      <SearchBar searchPatternCallback={onfilterChanged} />
      <Paper className={classes.paper}>
        <TableContainer>
          <Table size="small" className={classes.table}>
            <TableHead>
              {hasSummaryRow
                ? resovleSummaryHeaderRow(
                    columns,
                    filterData(data),
                    selected,
                    handleSelectAllClick
                  )
                : null}
              <TableRow>
                {hasSummaryRow ? (
                  <TableCell key={1} style={{ width: 40 }}></TableCell>
                ) : null}
                {columns.map((column) => (
                  <TableCell
                    key={column.header}
                    align={
                      column.isSummary || column.type === "percent"
                        ? "right"
                        : "left"
                    }
                    sortDirection={orderBy === column.field ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.field}
                      direction={orderBy === column.field ? order : "asc"}
                      onClick={() =>
                        handleRequestSort(column.field, column.type)
                      }
                    >
                      {column.header}
                      {orderBy === column.field ? (
                        <span className={classes.visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(filterData(), getComparator(order, orderBy, columns))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowItem) => {
                  const isItemSelected = isSelected(rowItem.id);

                  return (
                    <TableRow
                      key={rowItem.id}
                      onClick={(event) => handleClick(event, rowItem.id)}
                      selected={isItemSelected}
                      hover
                    >
                      {hasSummaryRow ? (
                        <TableCell style={{ width: 40 }}>
                          <Checkbox checked={isItemSelected} />
                        </TableCell>
                      ) : null}
                      {columns.map((colHeader) =>
                        resolveContentCell(colHeader, rowItem)
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={data.length}
          labelRowsPerPage="Liczba wierszy na stronie:"
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        ></TablePagination>
      </Paper>
    </div>
  );
};

export default SummaryTable;
