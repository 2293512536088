import React, { useCallback, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import dateFnsFormat from 'date-fns/format';


const SearchDateRange = ({searchDataCallback}) => {
    var today = new Date();
    const dateFormat = "dd.MM.yyyy";
    var defaultDateFrom = new Date(today.getFullYear(), 0, 1);
    var defaultDateTo = new Date(today.getFullYear(), today.getMonth(), today.getDate());

    const[dateFrom, setDateFrom] = useState(defaultDateFrom);
    const[dateTo, setDateTo] = useState(defaultDateTo);

    const searchItemsCallback = useCallback(() => searchDataCallback(dateFrom, dateTo), [dateFrom, dateTo]);

    const onDateFromChanged = dateFromValue => {
        setDateFrom(dateFromValue);
    }

    const onDateToChanged = dateToValue => {
        setDateTo(dateToValue);
    }

    function formatDate(date, format, locale)  {
        return dateFnsFormat(date, format, { locale });
    }

    function executeCallback() {
        searchItemsCallback();
    }

    return (
        <div>
            <label>Data od: </label>
             <DayPickerInput
                name="dateFrom"
                format={dateFormat}
                formatDate={formatDate}
                onDayChange={onDateFromChanged}
                value={dateFrom}
                selectedDate={dateFrom}
                className="form-control form-control-sm"
                id="dtDateInput"
                required />
            <label>Data do: </label>
            <DayPickerInput
                name="dateTo"
                format={dateFormat}
                formatDate={formatDate}
                onDayChange={onDateToChanged}
                value={dateTo}
                selectedDay={dateTo}
                className="form-control form-control-sm"
                id="dtDateInput"
                required />
            <button className="btn btn-primary" onClick={executeCallback} >Szukaj...</button>
        </div>
    )
};

export default SearchDateRange;