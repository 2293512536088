import React, { Component } from 'react';
import { MDBContainer } from 'mdbreact';
import ProjectTable from '../../features/project/projectTable';
import GrantSheetMenu from '../menu/menuNavBar';

import './projectList.css'

class ProjectList extends Component {
    state = {}
    render() {
        return (
            <div>
                <GrantSheetMenu />
                <MDBContainer fluid>
                    <ProjectTable />
                </MDBContainer>
            </div>
        );
    }
}

export default ProjectList;