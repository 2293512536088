import httpService from "../../../common-services/httpService";

export async function getDotationPersons() {
  const { data } = await httpService.get("/dotationPerson/getDotationPersons");
  return data;
}

export default {
  getDotationPersons,
};
