import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import client_path from "./constants/client_URL_paths";

import DonorList from "./components/layout/donorList/donorList";
import BankAccountList from "./components/layout/bankAccountList/bankAccountList";
import LoginPage from "./components//layout/login page/loginPage";

import DonorEditForm from "./components/layout/donorEditForm/donorEditForm";
import DonorDetailContainer from "./components/layout/donorList/donorDetailContainer";
import BankAccountEditForm from "./components/layout/bankAccountEditForm/bankAccountEditForm";
import ProtectedRoute from "./components/common components/protectedRoute/ProtectedRoute";
import ProjectList from "./components/layout/project/projectList";
import ProjectEditForm from "./components/layout/project/projectEditForm";
import GrantList from "./components/layout/grant/grantList";
import GrantEditForm from "./components/layout/grant/grantEditForm";

import ProjectCostEditForm from "./components/layout/project/projectCostEditForm";
import BalanceList from "./components/layout/balance/balanceList";
import BalanceAnalysisList from "./components/layout/balance/balanceAnalysisList";
import BalanceEditForm from "./components/layout/balance/balanceEditForm";
import ProjectBalanceList from "./components/layout/project/projectBalanceList";

import CostList from "./components/layout/cost/costList";
import CostEditForm from "./components/layout/cost/costEditForm";
import QuarterSettlement from "./components/layout/quarterSettlement/quarterSettlementForm";
import TransferPage from "./components/features/transfer/pages/transfer-page";
import TransferEditPage from "./components/features/transfer/pages/transfer-edit-page";
//import CashReport from './components/features/'
import CashReportList from "./components/layout/cash-report/cash-report-list";
import DotationList from "./components/layout/dotation/dotationList";
import DotationEdit from "./components/layout/dotation/dotationEdit";

class App extends Component {
  state = {
    role: "admin",
  };

  render() {
    document.title = "GrantSheet";
    return (
      <div className="App">
        <Switch>
          <ProtectedRoute
            path={client_path.donorList}
            redirectPath={client_path.loginPage}
            component={DonorList}
          />
          <ProtectedRoute
            path={`${client_path.donorEditForm}/:id`}
            redirectPath={client_path.loginPage}
            component={DonorEditForm}
          />

          <ProtectedRoute
            path={`${client_path.donorDetails}/:id`}
            redirectPath={client_path.loginPage}
            component={DonorDetailContainer}
          />

          <ProtectedRoute
            path={client_path.bankAccountList}
            redirectPath={client_path.loginPage}
            component={BankAccountList}
          />

          <ProtectedRoute
            path={`${client_path.bankAccountEditForm}/:id`}
            redirectPath={client_path.loginPage}
            component={BankAccountEditForm}
          />

          <ProtectedRoute
            path={client_path.projectList}
            redirectPath={client_path.loginPage}
            component={ProjectList}
          />

          <ProtectedRoute
            path={`${client_path.projectEditForm}/:id`}
            redirectPath={client_path.loginPage}
            component={ProjectEditForm}
          />

          <ProtectedRoute
            path={client_path.grantList}
            redirectPath={client_path.loginPage}
            component={GrantList}
          />

          <ProtectedRoute
            path={client_path.costList}
            role={this.state.role}
            redirectPath={client_path.loginPage}
            component={CostList}
          />

          <ProtectedRoute
            path={`${client_path.costEditForm}/:id`}
            role={this.state.role}
            redirectPath={client_path.loginPage}
            component={CostEditForm}
          />

          <ProtectedRoute
            path={`${client_path.grantEditForm}/:id`}
            redirectPath={client_path.loginPage}
            component={GrantEditForm}
          />

          <ProtectedRoute
            path={client_path.quarterSettlement}
            role={this.role}
            redirectPath={client_path.loginPage}
            component={QuarterSettlement}
          />

          <ProtectedRoute
            path={client_path.balanceAnalysisList}
            redirectPath={client_path.loginPage}
            component={BalanceAnalysisList}
          />

          <ProtectedRoute
            path={`${client_path.projectCostEditForm}/:costId/:projectId`}
            redirectPath={client_path.loginPage}
            component={ProjectCostEditForm}
          />

          <ProtectedRoute
            path={`${client_path.projectBalanceList}/:id`}
            redirectPath={client_path.loginPage}
            component={ProjectBalanceList}
          />

          <ProtectedRoute
            path={client_path.balanceList}
            redirectPath={client_path.loginPage}
            component={BalanceList}
          />

          <ProtectedRoute
            path={`${client_path.balanceEditForm}/:id`}
            redirectPath={client_path.loginPage}
            component={BalanceEditForm}
          />

          <ProtectedRoute
            path={`${client_path.transferPage}`}
            redirectPath={client_path.loginPage}
            component={TransferPage}
          />

          <ProtectedRoute
            path={`${client_path.transferEditPage}/:id`}
            redirectPath={client_path.loginPage}
            component={TransferEditPage}
          />

          <ProtectedRoute
            path={`${client_path.cashReportList}`}
            redirectPath={client_path.loginPage}
            component={CashReportList}
          />

          <ProtectedRoute
            exact
            path={`${client_path.dotationList}`}
            redirectPath={client_path.loginPage}
            component={DotationList}
          />

          <ProtectedRoute
            path={`${client_path.dotationEdit}/:id`}
            redirectPath={client_path.loginPage}
            component={DotationEdit}
          />

          <Route path={client_path.loginPage} component={LoginPage} />
          <Redirect to={client_path.grantList} />
        </Switch>
      </div>
    );
  }
}

export default App;
