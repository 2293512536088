import httpService from "../../../common-services/httpService";
import server_path from '../../../constants/server_URL_paths';
import authService from '../../../common-services/authService';

export async function getLoggedUser() {
    const { data } = await httpService.get(server_path.user.getLoggedUser);
    return data;
}

export async function logout() {
    await httpService.post(server_path.user.logout).then(() => {
        authService.removeTokenFromLocalStorage();
    });
}

export default {
    getLoggedUser,
    logout
}