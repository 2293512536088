import httpService from "../../../common-services/httpService";
import server_path from '../../../constants/server_URL_paths';


export async function applyQuarterSettlements(quarterSettlement) {
    const { data: item } = await httpService.post(`${server_path.quarterSettlements.applyQuarterSettlements}`, quarterSettlement);
    return item;
}

export async function getCurrentQuarterSettlements(){
    const { data: quarterSettlements } = await httpService.get(`${server_path.quarterSettlements.getCurrentQuarterQuarterSettlements}`);
    return quarterSettlements;
}

export async function getQuarterSettlements(quarter, year) {
    const { data: quarterSettlements } = await httpService.get(`${server_path.quarterSettlements.getQuarterSettlements}?quarter=${quarter}&year=${year}`);
    return quarterSettlements;
}


export default {
    getQuarterSettlements,
    applyQuarterSettlements,
    getCurrentQuarterSettlements
}
