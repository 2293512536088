import React from 'react';
import { withRouter } from 'react-router-dom';
import { MDBBtn } from 'mdbreact';
import client_paths from '../../../constants/client_URL_paths';

const AddNewProjectButton = (props) => {
    const handleAddNewProject = () => {
        props.history.push(`${client_paths.projectEditForm}/createProject`);
    }

    return (
        <MDBBtn size="sm" onClick={() => handleAddNewProject()}>Dodaj subkonto</MDBBtn>
    );
}

export default withRouter(AddNewProjectButton);