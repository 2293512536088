import React, { Component } from 'react'
import { MDBContainer } from 'mdbreact';
import GrantSheetMenu from '../menu/menuNavBar';
import QuarterSettlementSelector from '../../features/quarterSettlement/quarterSettlementSelector';

class QuarterSettlement extends Component
{ 
    state = {}
    render(){
        return (
            <div>
                <GrantSheetMenu/>
                <MDBContainer fluid>
                    <QuarterSettlementSelector/>
                </MDBContainer>
            </div>
        )
    }
}

export default QuarterSettlement