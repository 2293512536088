import React, { Component } from "react";
import { MDBBtn, MDBContainer, MDBRow, MDBCol } from "mdbreact";

import quarterService from "./quarterSettlementService";
import "./quarterSettlementSelector.css";

class QuarterSettlementSelector extends Component {
  state = {
    quarterItems: [],
    years: [],
    isQuarterCaluclated: false,
    isCalculationEnabled: false,
    selectedQuarter: 1,
    selectedYear: new Date().getFullYear(),
    isLoaded: false,
  };

  async componentDidMount() {
    var result = await quarterService.getCurrentQuarterSettlements();
    var currentYear = new Date().getFullYear();
    var years = [];

    years.push(currentYear);
    years.push(currentYear - 1);

    this.setState({
      years: years,
      isQuarterCaluclated: result.isCalculated,
      isCalculationEnabled: result.isCalculationEnabled,
      quarterItems: result.quarterItems,
      selectedQuarter: result.quarter,
      isLoaded: true,
    });
  }

  async calculateQuarter(searchQuarter, year) {
    var result = await quarterService.getQuarterSettlements(
      searchQuarter,
      year
    );

    this.setState({
      isQuarterCaluclated: result.isCalculated,
      isCalculationEnabled: result.isCalculationEnabled,
      quarterItems: result.quarterItems,
      selectedQuarter: searchQuarter,
      selectedYear: year,
      isLoaded: true,
    });
  }

  async applyQuarter() {
    const { quarterItems, selectedQuarter, selectedYear } = this.state;
    var postData = {
      quarterItems: quarterItems,
      quarter: selectedQuarter,
      year: selectedYear,
    };

    await quarterService.applyQuarterSettlements(postData).then(() => {
      window.location.reload();
    });
  }

  handleInputQuarter = (event) => {
    const { selectedYear } = this.state;
    this.calculateQuarter(event.target.value, selectedYear);
  };

  handleInputYear = (event) => {
    const { selectedQuarter } = this.state;
    this.calculateQuarter(selectedQuarter, event.target.value);
  };

  renderQuarterItems(quarterItems) {
    const { isLoaded, isQuarterCaluclated, isCalculationEnabled } = this.state;
    if (quarterItems.length === 0) {
      var emptyPart = isLoaded ? <h4>Brak danych</h4> : "";
      return <>{emptyPart}</>;
    }

    var actionButton = "";
    if (isQuarterCaluclated) {
      actionButton = <h4>Wybrany kwartał został już rozliczony</h4>;
    } else {
      actionButton = isCalculationEnabled ? (
        <MDBBtn
          color="success"
          size="sm"
          onClick={this.applyQuarter.bind(this)}
        >
          Rozlicz kwartał
        </MDBBtn>
      ) : (
        <div>
          <MDBBtn color="deep-orange" size="sm" disabled>
            Rozlicz kwartał{" "}
          </MDBBtn>
          Brak możliwości rozliczenia kwartału przed jego zakończeniem.
        </div>
      );
    }

    var quarterAction = "";
    if (quarterItems.length !== 0) {
      quarterAction = (
        <>
          <div className="form-group">{actionButton}</div>
        </>
      );
    }

    return (
      <>
        <div className="form-group">
          <div className="summaryTable">
            <table>
              <thead>
                <tr>
                  <th className="cellHeader">Subkonto</th>
                  <th className="cellHeader">Wpłaty (kwartał)</th>
                  <th className="cellHeader">Prowizja 1 (%)</th>
                  <th className="cellHeader">Wartość prowizji 1</th>
                  <th className="cellHeader">Prowizja 2 (%)</th>
                  <th className="cellHeader">Wartość prowizji 2</th>
                </tr>
              </thead>
              <tbody>
                {quarterItems.map((item) => {
                  return (
                    <tr key={item.projectId}>
                      <td className="cellData">{item.projectName}</td>
                      <td className="cellData">
                        {item.projectQuarterAmount} zł
                      </td>
                      <td className="cellData">{item.projectPercentage} %</td>
                      <td className="cellData">
                        {item.projectPrecentageValue} zł
                      </td>
                      <td className="cellData">
                        {item.projectSecondPercentage} %
                      </td>
                      <td className="cellData">
                        {item.projectSecondPercentageValue} zł
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        {quarterAction}
      </>
    );
  }

  render() {
    const { quarterItems, selectedQuarter, selectedYear, years } = this.state;
    return (
      <MDBContainer fluid>
        <form>
          <MDBRow>
            <MDBCol md="6">
              <div className="form-group gray-text">
                <h4>
                  <strong>Rozliczenia kwartalne</strong>
                </h4>

                <label htmlFor="cbxYears">Wybierz rok</label>
                <select
                  className="browser-default custom-select"
                  id="cbxYears"
                  onChange={this.handleInputYear}
                  value={selectedYear}
                >
                  {years.map((year) => {
                    return (
                      <option value={year} key={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>

                <label htmlFor="cbxQuarters">Wybierz kwartał</label>
                <select
                  className="browser-default custom-select"
                  id="cbxQuarters"
                  onChange={this.handleInputQuarter}
                  value={selectedQuarter}
                >
                  <option value="1">I kwartał</option>
                  <option value="2">II kwartał</option>
                  <option value="3">III kwartał</option>
                  <option value="4">IV kwartał</option>
                </select>
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6">{this.renderQuarterItems(quarterItems)}</MDBCol>
          </MDBRow>
        </form>
      </MDBContainer>
    );
  }
}

export default QuarterSettlementSelector;
