import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import Spinner from '../../common components/spinner/spinner';
import AddBalanceButton from '../../features/balance/addBalanceButton';
import balanceService from "./balanceService";
import client_path from '../../../constants/client_URL_paths';
import './balance.css';

class BalanceTable extends Component {
    state = {
        dataToshow: [],
        columns: [
            { label: "Lp.", field: 'index', sort: 'asc', width: 50 },
            { label: "Projekt", field: 'projectName', sort: 'asc', width: 120 },
            { label: "Data otwarcia", field: 'openDate', sort: 'asc', width: 120 },
            { label: "Bilans otwarcia", field: 'openAmount', sort: 'asc', width: 120 },
            { label: "Data zamknięcia", field: 'closeDate', sort: 'asc', width: 100 },
            { label: "Bilans zamknięcia", field: 'closeAmount', sort: 'asc', width: 100 },
            { label: "", field: 'actions', sort: false, width: 130 },
        ],

        isLoaded: false
    }

    async componentDidMount() {
        const data = await balanceService.getBalances();

        const dataToshow = data.map((balance, index) => {
            return {
                index: index + 1,
                projectName: balance.projectName,
                openDate: balance.openDate,
                openAmount: balance.openAmount,
                closeDate: balance.closeDate,
                closeAmount: balance.closeAmount,
                actions:
                    <>
                        <span className="editButton" onClick={() => this.redirectToBalanceEditForm(balance)}>Edytuj</span>
                    </>
            }
        })

        this.setState({ dataToshow, isLoaded: true })
    }

    redirectToBalanceEditForm(balance) {
        this.props.history.push(`${client_path.balanceEditForm}/${balance.id}`)
    }

    render() {
        const { columns, dataToshow, isLoaded } = this.state;

        const data = {
            columns,
            rows: dataToshow
        }

        var renderedPart = !isLoaded ? <Spinner /> :
            <div>
                <div className="d-flex justify-content-between">
                    <h3 className="text-center pt-2">Bilanse</h3>
                    <AddBalanceButton />
                </div>
                <MDBDataTable
                    scrollY
                    maxHeight="65vh"
                    entries={100}
                    bordered
                    small
                    hover
                    data={data}/>

            </div>

        return (
            <>
                {renderedPart}
            </>
        );
    }
}

export default withRouter(BalanceTable);
