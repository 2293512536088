import React from 'react';
import { MDBJumbotron } from "mdbreact";
import EditForm from '../../features/balance/balanceEdit';
import './balance.css';

const BalanceEditForm = () => {
    return (
        <div className="balanceEditForm-box p-5">
            <MDBJumbotron style={{ width: "800px" }}>
                <EditForm />
            </MDBJumbotron>
        </div>

    );
}

export default BalanceEditForm;