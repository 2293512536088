import React, { Component } from 'react'
import { MDBContainer } from 'mdbreact';
import Transfers from '../components/transfer-list';
import GrantSheetMenu from '../../../layout/menu/menuNavBar';

export class TransferPage extends Component {
    render() {
        return (
            <div>
                <GrantSheetMenu />
                <MDBContainer fluid>
                    <Transfers></Transfers>
                </MDBContainer>
            </div>
        );
    }
}

export default TransferPage;