import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBDataTable, MDBContainer } from 'mdbreact';
import dictionary_service from '../../../common-services/dictionaryService';
import BalanceAnalysisSummaryTable from './balanceAnalysisSummaryTable';
import balanceService from "./balanceService";
import './balance.css';

class BalanceAnalysisTable extends Component {
    state = {
        dataToShow: [],
        projectSuggestions: [],
        balanceItem: null,
        projectId: "",
        columns: [
            { label: "Lp.", field: 'index', sort: 'asc', width: 50 },
            { label: "Data", field: 'date', sort: 'asc', width: 120 },
            { label: "Nazwa", field: 'displayText', sort: 'asc', width: 120 },
            { label: "Kwota", field: 'amount', sort: 'asc', width: 120 }
        ],

        isLoaded: false
    }

    async componentDidMount() {

        const projectSuggestionItems = await dictionary_service.getProjectSuggestions();
        this.setState({ projectSuggestions: projectSuggestionItems, isLoaded: true })
    }

    onProjectChange = (event) => {

        var selectedProjectId = event.target.value
        balanceService.getBalanceAnalyses(selectedProjectId).then(data => {

            const dataToRender = data.items.map((ba, index) => {
                return {

                    index: index + 1,
                    date: ba.date,
                    displayText: ba.displayText,
                    amount: ba.amount
                }
            });

            this.setState({ projectId: selectedProjectId, dataToShow: dataToRender, balanceItem: data });
        });

    }

    render() {
        const { columns, projectSuggestions, projectId, dataToShow } = this.state;
        const data = {
            columns,
            rows: dataToShow
        }

        return (
            <MDBContainer fluid>
                <div>
                    Projekt:
                        <select className="browser-default custom-select" onChange={this.onProjectChange} value={projectId}>
                        {projectSuggestions.map(project => {
                            return (
                                <option value={project.id} key={project.id}>{project.displayMember}</option>
                            )
                        })}
                    </select>

                </div>
                <div>
                    <BalanceAnalysisSummaryTable balanceItem={this.state.balanceItem} />
                </div>
                <div className="summaryTable">
                    <h4>Szczegóły bilansu:</h4>

                    <MDBDataTable
                        scrollY
                        maxHeight="65vh"
                        entries={100}
                        bordered
                        small
                        data={data}
                        hover />

                </div>
            </MDBContainer>
        );
    }
}

export default withRouter(BalanceAnalysisTable);
