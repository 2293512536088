import httpService from "../../../../common-services/httpService";
import server_path from '../../../../constants/server_URL_paths';

export async function getTransfers() {
    const { data } = await httpService.get(server_path.transfers.getTransfers);
    return data;
}

export async function getTransfer(transferId) {
    const { data: transfer } = await httpService.get(`${server_path.transfers.getTransfer}?transferId=${transferId}`);
    return transfer;
}

export async function update(transfer) {
    const { data: result } = await httpService.put(`${server_path.transfers.update}`, transfer);
    return result;
}

export async function create(transfer) {
    const { data: result } = await httpService.post(`${server_path.transfers.create}`, transfer);
    return result;
}

export async function deleteTransfer(transferId) {
    const { data: transfer } = await httpService.delete(`${server_path.transfers.delete}?transferId=${transferId}`);
    return transfer;
}

export default {
    getTransfers,
    getTransfer,
    create,
    update,
    deleteTransfer
}