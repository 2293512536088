import httpService from "../../../common-services/httpService";
import server_path from '../../../constants/server_URL_paths';

export async function getCosts(costRequest) {
    const { data } = await httpService.post(`${server_path.cost.getCosts}`, costRequest);
    return data;
}

export async function getProjectCosts(projectId) {
    const { data } = await httpService.get(`${server_path.cost.getProjectCosts}?projectId=${projectId}`);
    return data;
}

export async function getCostById(costId) {
    const { data: cost } = await httpService.get(`${server_path.cost.getCostById}?costId=${costId}`);
    return cost;
}

export async function getCostTypes() {
    const { data } = await httpService.get(server_path.cost.getCostTypes);
    return data;
}

export async function getDefaultCostType() {
    const { data } = await httpService.get(server_path.cost.getDefaultCostType);
    return data;
}

export async function update(cost) {
    const { data: foundCost } = await httpService.put(`${server_path.cost.update}`, cost);
    return foundCost;
}

export async function create(cost) {
    const { data: foundCost } = await httpService.post(`${server_path.cost.create}`, cost);
    return foundCost;
}

export async function deleteCost(costId) {
    const { data: foundCost } = await httpService.delete(`${server_path.cost.delete}?costId=${costId}`);
    return foundCost;
}

export default {
    getCosts,
    getCostById,
    update,
    create,
    deleteCost,
    getProjectCosts,
    getCostTypes,
    getDefaultCostType
}
