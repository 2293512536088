import React, { Component } from 'react';
import BalanceAnalysisTable from '../../features/balance/balanceAnalysisTable';
import GrantSheetMenu from '../menu/menuNavBar';
import './balance.css'

class BalanceAnalysisList extends Component {
    state = {}
    render() {
        return (
            <div>
                <GrantSheetMenu />
                <div className="balanceList-container">

                    <BalanceAnalysisTable />

                </div>
            </div>
        );
    }
}

export default BalanceAnalysisList;