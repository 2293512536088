import React from 'react';
import { MDBJumbotron, MDBContainer } from "mdbreact";
import LoginForm from '../../features/loginForm/loginForm';
import './loginPage.css';

const LoginPage = () => {
    return (
        <div className="loginPage-box pt-5">

            <MDBContainer
                className="text-center d-flex justify-content-center"
            >
                <MDBJumbotron style={{ width: "400px" }}>
                    <LoginForm />
                </MDBJumbotron>
            </MDBContainer>
        </div>

    );
}

export default LoginPage;