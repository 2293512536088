import React from 'react';
import { withRouter } from 'react-router-dom';
import { MDBBtn } from 'mdbreact';
import client_paths from '../../../../constants/client_URL_paths';

const TransferCreateButton = (props) => {
    const handleCreate = () => {
        props.history.push(`${client_paths.transferEditPage}/createTransfer`);
    }

    return (
        <MDBBtn size="sm" onClick={() => handleCreate()}>Dodaj transfer</MDBBtn>
    );
}

export default withRouter(TransferCreateButton);