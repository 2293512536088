import React, { Component } from "react";
import { MDBRow, MDBCol } from "mdbreact";
import { withRouter } from "react-router-dom";
import editForm_service from "./grantService";
import dictionary_service from "../../../common-services/dictionaryService";
import client_path from "../../../constants/client_URL_paths";
import bankAccountService from "../bankAccount/bankAccountService";
import Autosuggest from "react-autosuggest";
import Spinner from "../../common components/spinner/spinner";
import { DateUtils } from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "react-day-picker/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "./grant.css";

const theme = {
  container: {
    position: "relative",
  },
  input: {
    width: 240,
    height: 30,
    padding: "10px 20px",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    border: "1px solid #aaa",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    top: 51,
    width: 280,
    border: "1px solid #aaa",
    backgroundColor: "#fff",
    fontFamily: "Helvetica, sans-serif",
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  suggestion: {
    cursor: "pointer",
    padding: "10px 20px",
  },
  suggestionHighlighted: {
    backgroundColor: "#ddd",
  },
};

var donors = [];

const getDonorSuggestions = (value) => {
  const inputValue = value.trim().toLowerCase();

  var items = [];
  for (var index = 0; index < donors.length; index++) {
    var donorName = donors[index].text.toLowerCase();
    var isMatch = donorName.search(inputValue);
    if (isMatch !== -1) {
      items.push(donors[index]);
    }
  }

  return items;
};

const getDonorSuggestionValue = (suggestion) => suggestion.displayMember;

const renderSuggestion = (suggestion) => <div>{suggestion.text}</div>;

class GrantEdit extends Component {
  state = {
    incomeInfo: {
      incomeDate: new Date(),
      donorId: "",
      projectId: "",
      bankAccountId: "",
      incomeAmount: 0,
      date: new Date(),
      donorDisplayMember: "",
      description: "",
      isPeriodicCollection: false,
    },

    value: "",

    donorSuggestions: [],
    projectSuggestions: [],
    bankAccountSuggestions: [],
    loadedData: false,
  };

  async componentDidMount() {
    try {
      const donorSuggestionItems =
        await dictionary_service.getDonorSuggestions();
      const projectSuggestionItems =
        await dictionary_service.getProjectSuggestions();
      const bankAccountSuggestionItems =
        await dictionary_service.getBankAccountSuggestions();
      const defaultBankAccount =
        await bankAccountService.getDefaultBankAccount();
      const defaultProject = await dictionary_service.getGrantDefaultProject();
      donors = donorSuggestionItems;

      const incomeId = this.props.match.params.id;
      if (incomeId === "createGrant") {
        const { incomeInfo } = this.state;
        incomeInfo.incomeDate = new Date();

        if (defaultBankAccount !== null) {
          incomeInfo.bankAccountId = defaultBankAccount.bankAccountId;
        }

        if (
          projectSuggestionItems !== null &&
          projectSuggestionItems.length !== 0
        ) {
          incomeInfo.projectId = defaultProject.id;
        }

        this.setState({
          incomeInfo: incomeInfo,
          loadedData: true,
          donorSuggestions: donorSuggestionItems,
          projectSuggestions: projectSuggestionItems,
          bankAccountSuggestions: bankAccountSuggestionItems,
          isPeriodicCollection: false,
        });
        return;
      }

      const foundGrant = await editForm_service.getGrantById(incomeId);
      var reversDate = foundGrant.incomeDate.split(".").reverse().join("-");

      foundGrant.incomeDate = new Date(reversDate);
      var valueMember = foundGrant.donorDisplayMember;
      this.setState({
        incomeInfo: foundGrant,
        loadedData: true,
        donorSuggestions: donorSuggestionItems,
        projectSuggestions: projectSuggestionItems,
        bankAccountSuggestions: bankAccountSuggestionItems,
        value: valueMember,
      });
    } catch (ex) {
      throw Error(ex);
    }
  }

  handleCancel = () => {
    this.props.history.push(client_path.grantList);
  };

  submitHanlder = (event) => {
    event.target.className += " was-validated";
    event.preventDefault();

    if (this.isValid()) {
      try {
        const { incomeInfo } = this.state;
        const incomeId = this.props.match.params.id;

        if (incomeId === "createGrant") {
          editForm_service.createGrant(incomeInfo).then(() => {
            this.props.history.push(client_path.grantList);
          });
        } else {
          editForm_service.updateGrant(incomeInfo).then(() => {
            this.props.history.push(client_path.grantList);
          });
        }
      } catch (ex) {
        console.log("error", ex);
      }
    }
  };

  isValid() {
    const { incomeInfo } = this.state;
    if (incomeInfo.incomeAmount <= 0) {
      return false;
    }

    if (incomeInfo.donorId === "") {
      return false;
    }

    var amountValue = incomeInfo.incomeAmount.toString().replace(",", ".");
    incomeInfo.incomeAmount = amountValue;
    return true;
  }

  handleCheckBoxChange = (event) => {
    const { incomeInfo } = this.state;
    incomeInfo.isPeriodicCollection = event.target.checked;

    this.setState({ incomeInfo });
  };

  onDonorSuggestionsFetchRequested = ({ value }) => {
    this.setState({ donorSuggestions: getDonorSuggestions(value) });
  };

  onDonorSuggestionsClearRequested = () => {
    this.setState({
      donorSuggestions: [],
    });
  };

  onDonorSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    const { incomeInfo } = this.state;
    incomeInfo.donorId = suggestion.id;
    incomeInfo.donorDisplayMember = suggestion.text;
    this.setState({ incomeInfo });
  };

  onDonorSuggestChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  handleInputOnChange = ({ currentTarget }) => {
    const property = currentTarget.getAttribute("name");
    const { incomeInfo } = this.state;
    incomeInfo[property] = currentTarget.value;
    this.setState({ incomeInfo });
  };

  onDayChange = (date) => {
    const { incomeInfo } = this.state;
    incomeInfo.incomeDate = date;
    this.setState({ incomeInfo });
  };

  onProjectChange = (event) => {
    const { incomeInfo } = this.state;
    incomeInfo.projectId = event.target.value;
    this.setState({ incomeInfo });
  };

  onBankAccountChange = (event) => {
    const { incomeInfo } = this.state;
    incomeInfo.bankAccountId = event.target.value;
    this.setState({ incomeInfo });
  };

  formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  render() {
    const {
      donorSuggestions,
      projectSuggestions,
      bankAccountSuggestions,
      incomeInfo,
      loadedData,
      value,
    } = this.state;

    const donorSuggestionInputProps = {
      placeholder: "Wprowadź darczyńce",
      value,
      onChange: this.onDonorSuggestChange,
    };

    const dateFormat = "dd.MM.yyyy";
    return (
      <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHanlder.bind(this)}
          noValidate
        >
          <h1 className="text-center">Wpłata darczyńcy</h1>
          {!loadedData ? (
            <Spinner />
          ) : (
            <div>
              <MDBRow>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="dtDateInput" className="gray-text">
                    Data
                  </label>
                  <div>
                    <DayPickerInput
                      name="incomeDate"
                      format={dateFormat}
                      formatDate={this.formatDate}
                      onDayChange={this.onDayChange}
                      value={incomeInfo.incomeDate}
                      className="form-control form-control-sm"
                      id="dtDateInput"
                      required
                    />
                  </div>
                </MDBCol>

                <MDBCol md="4" className="mb-3">
                  <label htmlFor="cbxDonor" className="gray-text">
                    Darczyńca
                  </label>
                  <Autosuggest
                    id="cbxDonor"
                    suggestions={donorSuggestions}
                    onSuggestionsFetchRequested={
                      this.onDonorSuggestionsFetchRequested
                    }
                    onSuggestionsClearRequested={
                      this.onDonorSuggestionsClearRequested
                    }
                    getSuggestionValue={getDonorSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={donorSuggestionInputProps}
                    onSuggestionSelected={this.onDonorSuggestionSelected}
                    theme={theme}
                    required
                  />
                </MDBCol>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtAmount" className="gray-text">
                    Kwota
                  </label>
                  <input
                    type="text"
                    pattern="[0-9]+([\.,][0-9]+[0-9])?"
                    name="incomeAmount"
                    value={incomeInfo.incomeAmount}
                    className="form-control form-control-sm"
                    onChange={this.handleInputOnChange}
                    id="txtIncomeAmount"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label className="gray-text" htmlFor="cbxProjects">
                    Subkonto
                  </label>
                  <select
                    id="cbxProjects"
                    className="browser-default custom-select"
                    onChange={this.onProjectChange}
                    value={incomeInfo.projectId}
                  >
                    {projectSuggestions.map((project) => {
                      return (
                        <option value={project.id} key={project.id}>
                          {project.displayMember}
                        </option>
                      );
                    })}
                  </select>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label className="gray-text" htmlFor="cbxBankAccount">
                    Konto bankowe
                  </label>
                  <select
                    id="cbxBankAccount"
                    className="browser-default custom-select"
                    onChange={this.onBankAccountChange}
                    value={incomeInfo.bankAccountId}
                  >
                    {bankAccountSuggestions.map((bankAccount) => {
                      return (
                        <option value={bankAccount.id} key={bankAccount.id}>
                          {bankAccount.displayMember}
                        </option>
                      );
                    })}
                  </select>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label className="gray-text" htmlFor="txtDescription">
                    Uwagi
                  </label>
                  <textarea
                    id="txtDescription"
                    className="form-control form-control-sm"
                    onChange={this.handleInputOnChange}
                    value={incomeInfo.description}
                    name="description"
                    rows="3"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6" className="mb-3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={incomeInfo.isPeriodicCollection}
                        onChange={this.handleCheckBoxChange}
                        name="chkIsWebsite"
                        color="primary"
                      />
                    }
                    label="Czy wpłata zbiórki okresowej ?"
                  />
                </MDBCol>
              </MDBRow>

              <div className="text-center">
                <button className="btn btn-white" onClick={this.handleCancel}>
                  Odrzuć
                </button>
                <button className="btn btn-primary" type="submit">
                  Zapisz
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default withRouter(GrantEdit);
