import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MDBBtn, MDBRow, MDBCol } from "mdbreact";
import Spinner from "../../common components/spinner/spinner";
import editForm_service from "./editFormService";
import client_path from "../../../constants/client_URL_paths";

import "./editForm.css";

class EditForm extends Component {
  state = {
    firstName: "",
    donorInformation: {
      code: "",
      firstName: "",
      lastName: "",
      street: "",
      postalCode: "",
      city: "",
      greetings: false,
      description: "",
      email: "",
      phone: "",
    },
    inputs: [
      { label: "Kod darczyńcy", property: "code" },
      { label: "Imię", property: "firstName" },
      { label: "Nazwisko", property: "lastName" },
      { label: "Adres e-mail", property: "email" },
      { label: "Telefon", property: "phone" },
      { label: "Miasto", property: "city" },
      { label: "Ulica", property: "street" },
      { label: "Kod pocztowy", property: "postalCode" },
    ],
    loadedData: false,
  };

  async componentDidMount() {
    try {
      const donorId = this.props.match.params.id;
      if (donorId === "createNewDonor") {
        this.setState({ loadedData: true });
        return;
      }

      const foundDonor = await editForm_service.getDonorById(donorId);
      this.setState({ donorInformation: foundDonor, loadedData: true });
    } catch (ex) {
      throw Error(ex);
    }
  }

  handleCancel = () => {
    this.props.history.push(client_path.donorList);
  };

  handleSave = () => {
    try {
      const { donorInformation } = this.state;
      const donorId = this.props.match.params.id;
      if (donorId === "createNewDonor") {
        editForm_service.createDonor(donorInformation);
      } else {
        editForm_service.updateDonor(donorInformation);
      }
      this.props.history.push(client_path.donorList);
    } catch (ex) {
      console.log("error", ex);
    }
  };

  handleInputOnChange = ({ currentTarget }) => {
    const property = currentTarget.getAttribute("name");
    const { donorInformation } = this.state;
    donorInformation[property] = currentTarget.value;
    this.setState({ donorInformation });
  };

  handleChangeRegards = () => {
    const { donorInformation } = this.state;
    donorInformation.greetings = !donorInformation.greetings;
    this.setState({ donorInformation });
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitHanlder = (event) => {
    event.target.className += " was-validated";
    event.preventDefault();

    // validate controls
    if (this.isValid()) {
      try {
        const { donorInformation } = this.state;
        const donorId = this.props.match.params.id;

        if (donorId === "createNewDonor") {
          editForm_service.createDonor(donorInformation).then(() => {
            this.props.history.push(client_path.donorList);
          });
        } else {
          editForm_service.updateDonor(donorInformation).then(() => {
            this.props.history.push(client_path.donorList);
          });
        }
      } catch (ex) {
        console.log("error", ex);
      }
    }
  };

  isValid() {
    const { donorInformation } = this.state;

    var isValid = true;
    if (
      donorInformation.firstName === null ||
      donorInformation.firstName === ""
    ) {
      isValid = false;
    }

    if (
      donorInformation.lastName === null ||
      donorInformation.lastName === ""
    ) {
      isValid = false;
    }

    return isValid;
  }

  render() {
    const { donorInformation, loadedData } = this.state;

    return (
      <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHanlder.bind(this)}
          noValidate
        >
          <h1 className="text-center">Dane darczyńcy</h1>
          {!loadedData ? (
            <Spinner />
          ) : (
            <div>
              <MDBRow>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtCode" className="gray-text">
                    Kod darczyńcy
                  </label>
                  <input
                    type="text"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={donorInformation.code}
                    id="txtCode"
                    name="code"
                  />
                </MDBCol>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtFirstName" className="gray-text">
                    Imię
                  </label>
                  <input
                    type="text"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={donorInformation.firstName}
                    id="txtFirstName"
                    name="firstName"
                    required
                  />
                </MDBCol>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtLastName" className="gray-text">
                    Nazwisko
                  </label>
                  <input
                    type="text"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={donorInformation.lastName}
                    id="txtLastName"
                    name="lastName"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtStreet" className="gray-text">
                    Ulica
                  </label>
                  <input
                    type="text"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={donorInformation.street}
                    id="txtStreet"
                    name="street"
                  />
                </MDBCol>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtPostalCode" className="gray-text">
                    Kod-pocztowy
                  </label>
                  <input
                    type="text"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={donorInformation.postalCode}
                    id="txtPostalCode"
                    name="postalCode"
                  />
                </MDBCol>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtCity" className="gray-text">
                    Miasto
                  </label>
                  <input
                    type="text"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={donorInformation.city}
                    id="txtCity"
                    name="city"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtEmail" className="gray-text">
                    E-mail
                  </label>
                  <input
                    type="email"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={donorInformation.email}
                    id="txtEmail"
                    name="email"
                  />
                </MDBCol>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtPhone" className="gray-text">
                    Telefon
                  </label>
                  <input
                    type="text"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={donorInformation.phone}
                    id="txtPhone"
                    name="phone"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="txtDescription" className="gray-text">
                    Uwagi
                  </label>
                  <textarea
                    rows="3"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={donorInformation.description}
                    id="txtDescription"
                    name="description"
                  />
                </MDBCol>
              </MDBRow>
              <div className="text-center">
                <MDBBtn color="btn btn-white" onClick={this.handleCancel}>
                  Odrzuć
                </MDBBtn>
                <MDBBtn color="btn btn-primary" type="submit">
                  Zapisz
                </MDBBtn>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default withRouter(EditForm);
