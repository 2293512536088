import httpService from "../../../common-services/httpService";
import server_path from '../../../constants/server_URL_paths';

export async function getBalances() {
    const { data } = await httpService.get(server_path.balance.getBalances);
    console.log(data);
    return data;
}

export async function getBalanceAnalyses(projectId) {
    const { data } = await httpService.get(`${server_path.balance.getBalanceAnalyses}?projectId=${projectId}`);
    return data;
}

export async function getBalanceById(balanceId) {
    const { data: balance } = await httpService.get(`${server_path.balance.getBalanceById}?balanceId=${balanceId}`);
    return balance;
}

export async function update(balance) {
    const { data: foundBalance } = await httpService.put(`${server_path.balance.update}`, balance);
    return foundBalance;
}

export async function create(balance) {
    const { data: foundBalance } = await httpService.post(`${server_path.balance.create}`, balance);
    return foundBalance;
}

export default {
    getBalances,
    getBalanceAnalyses,
    create,
    update,
    getBalanceById
}
