import httpService from "../../../common-services/httpService";
import server_path from '../../../constants/server_URL_paths';

export async function getDonorById(donorId) {
    const { data: foundDonor } = await httpService.get(`${server_path.donor.getdonor}?donorId=${donorId}`);
    return foundDonor;
}

export async function updateDonor(donor) {
    const { data: donorData } = await httpService.put(`${server_path.donor.updateDonor}`, donor);
    return donorData;
}

export async function createDonor(donor) {
    const { data: donorData } = await httpService.post(`${server_path.donor.createDonor}`, donor);
    return donorData;
}
export default {
    getDonorById,
    updateDonor,
    createDonor
}