import React from 'react';
import { MDBJumbotron } from "mdbreact";
import EditForm from '../../features/grant/grantEdit';
import './grant.css';

const GrantEditForm = () => {
    return (
        <div className="grantEditForm-box p-5">
            <MDBJumbotron style={{ width: "800px" }}>
                <EditForm />
            </MDBJumbotron>
        </div>

    );
}

export default GrantEditForm;