import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
    MDBContainer,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter
} from 'mdbreact';

import Spinner from '../../common components/spinner/spinner';
import AddNewDonorButton from '../../features/addNewDonor/addNewDonor';
import donorService from "./donorService";
import client_path from '../../../constants/client_URL_paths';
import SummaryTable from '../../table/summaryTable'
import './donorTable.css';

class DonorTable extends Component {
    state = {
        data: [],
        columns: [
            { header: "Kod", field: 'code', sort: 'asc', width: 100 },
            { header: "Nazwisko i imię", field: 'fullName', sort: 'asc', width: 200 },
            { header: "Adres", field: 'fullAdress', sort: 'asc', width: 250 },
            { header: "Email", field: 'email', sort: 'asc', width: 220 },
            { header: "Telefon", field: 'phone', sort: 'asc', width: 220 },
            { header: "Uwagi", field: 'description', sort: 'asc' },
            { header: "", field: 'actions', sort: false, width: 100 },
        ],
        isLoaded: false,
        confirmDelete: false,
        donorToDelete: null,
    }

    async componentDidMount() {
        const items = await donorService.getDonors();

        const data = items.map((donor, index) => {
            var donorFullAddress = `${donor.postalCode} ${donor.city}, ${donor.street}`
            var donorName = `${donor.lastName} ${donor.firstName}`
            
            if (donor.street === null || donor.street === "") {
                donorFullAddress = `${donor.postalCode} ${donor.city}`
            }

            return {

                code: donor.code,
                index: index + 1,
                fullName: donorName,
                email: `${donor.email}`,
                phone: donor.phone,
                fullAdress: donorFullAddress,
                description: donor.description,
                actions:
                    <>
                        <span className="editButton" onClick={() => this.redirectToDonorDetails(donor.id)}>Wpłaty | </span>
                        <span className="editButton" onClick={() => this.redirectToDonorEditForm(donor)}>Edytuj | </span>
                        <span className="editButton" onClick={() => this.confirmDonorDelete(donor)}>Usuń </span>
                        
                    </>
            }
        })

        this.setState({ data, isLoaded: true })
    }

    redirectToDonorDetails(donorId) {
        this.props.history.push(`${client_path.donorDetails}/${donorId}`);
    }

    redirectToDonorEditForm(donorData) {
        this.props.history.push(`${client_path.donorEditForm}/${donorData.id}`)
    }

    cancelDelete = () => {
        this.setState({
            confirmDelete: !this.state.confirmDelete
        });
    }

    confirmDonorDelete(donor) {

        this.setState({ donorToDelete: donor, confirmDelete: true });
    }

    deleteDonor() {
        const { donorToDelete } = this.state;
        donorService.deleteDonor(donorToDelete.id).then(() => {
            window.location.reload();
        });

    }

    render() {

        var deleteModal = "";
        const { columns, data, isLoaded, confirmDelete, donorToDelete } = this.state;
        

        if (confirmDelete) {
            var donorName = `${donorToDelete.firstName} ${donorToDelete.lastName}`;
            deleteModal = <MDBContainer>
                <MDBModal isOpen={this.state.confirmDelete} toggle={this.cancelDelete}>
                    <MDBModalHeader toggle={this.cancelDelete}>Usuwanie darczyńcy</MDBModalHeader>
                    <MDBModalBody>
                        Czy na pewno chcesz usunąć daczyńcę '{donorName}' ?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="light" onClick={this.cancelDelete}>Nie</MDBBtn>
                        <MDBBtn color="primary" onClick={this.deleteDonor.bind(this)}>Tak</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        }

        var renderedPart = !isLoaded ? <Spinner /> :
            <div>
                <div className="d-flex justify-content-between">
                    <h3 className="text-center pt-2">Lista darczyńców</h3>
                    <AddNewDonorButton />
                </div>
                <SummaryTable 
                    columns={columns}
                    data={data}
                    defaultOrderBy="fullName"
                    defaultOrder="asc"
                     />
                {deleteModal}
            </div>
        return (
            <>
                {renderedPart}
            </>
        );
    }
}

export default withRouter(DonorTable);
