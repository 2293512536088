import React from 'react';
import { MDBJumbotron } from "mdbreact";
import EditForm from '../../features/bankAccount/bankAccountEdit';
import './bankAccountEditForm.css';

const BankAccountEditForm = () => {
    return (
        <div className="bankAccountEditForm-box p-5">
            <MDBJumbotron style={{ width: "800px" }}>
                <EditForm />
            </MDBJumbotron>
        </div>

    );
}

export default BankAccountEditForm;