import React, { Component } from 'react';
import { MDBContainer } from 'mdbreact';
import GrantTable from '../../features/grant/grantTable';
import GrantSheetMenu from '../menu/menuNavBar';
import './grant.css'

class GrantList extends Component {
    state = {}
    render() {
        return (
            <div>
                <GrantSheetMenu />
                <MDBContainer fluid>
                    <GrantTable />
                </MDBContainer>
            </div>
        );
    }
}

export default GrantList;