import React from 'react';
import { withRouter } from 'react-router-dom';
import { MDBBtn  } from 'mdbreact';
import client_paths from '../../../constants/client_URL_paths';

const AddNewDonorButton = (props) => {
    const handleAddNewDonor = () => {
       props.history.push(`${client_paths.donorEditForm}/createNewDonor`);
    }

    return ( 
        <MDBBtn size="sm" onClick={() => handleAddNewDonor()}>Dodaj darczyńce</MDBBtn>
     );
}
 
export default withRouter(AddNewDonorButton);