import React, { useState, useEffect } from "react";
import { MDBJumbotron, MDBRow, MDBCol } from "mdbreact";
import DayPickerInput from "react-day-picker/DayPickerInput";
import dateFnsFormat from "date-fns/format";
import client_path from "../../../constants/client_URL_paths";
import { useHistory, useParams } from "react-router-dom";

import dotationService from "../../features/dotation/dotation.service";
import dotationPersonService from "../../features/dotation-person/dotation-person.service";
import countryService from "../../features/country/country.service";
import projectService from "../../features/project/projectService";
import costService from "../../features/cost/costService";
import dictionary_service from "../../../common-services/dictionaryService";
import Spinner from "../../common components/spinner/spinner";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "./dotation.css";

const DotationEdit = () => {
  const history = useHistory();

  const [projects, setProjects] = useState([]);
  const [costTypes, setCostTypes] = useState([]);
  const [dotationPersons, setDotationPersons] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [number, setNumber] = useState("");
  const [countryId, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [dotationPersonId, setDotationPerson] = useState(0);
  const [costTypeId, setCostTypeId] = useState("");
  const [dotationDate, setDotationDate] = useState(new Date());
  const [projectId, setProject] = useState("");
  const [amount, setAmount] = useState(0);
  const [amountCurrency, setAmountCurrency] = useState("");
  const [description, setDescription] = useState("");
  const [purpose, setPurpose] = useState("");
  const [settlementDate, setSettlementDate] = useState(null);
  const [documentScanDate, setDocumentScanDate] = useState(null);
  const [documentOriginalDate, setDocumentOriginalDate] = useState(null);
  const [hasPhotographicDocumentation, setHasPhotographicDocumentation] =
    useState(false);

  const queryParams = useParams();
  const dotationId = queryParams.id;
  const isNew = dotationId === "0";

  function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  function handleCancel() {
    history.push(client_path.dotationList);
  }

  const handleCheckBoxChange = (event) => {
    setHasPhotographicDocumentation(event.target.checked);
  };

  async function save(event) {
    event.preventDefault();

    if (!settlementDate) {
      alert("Proszę podać datę rozliczenia dotacji");
    }

    const convertedAmount = amount.toString().replace(",", ".");
    var request = {
      number: number,
      city: city,
      amount: convertedAmount,
      amountCurrency: amountCurrency,
      description: description,
      projectId: projectId,
      dotationPersonId: dotationPersonId,
      countryId: countryId,
      costTypeId: costTypeId,
      dotationDate: dotationDate,
      settlementDate: settlementDate,
      documentScanDate: documentScanDate,
      documentOriginalDate: documentOriginalDate,
      purpose: purpose,
      hasPhotographicDocumentation: hasPhotographicDocumentation,
    };

    if (isNew === true) {
      console.log("create a new dotation");
      console.log(request);
      await dotationService.create(request);
    } else {
      request.dotationId = dotationId;
      await dotationService.update(request);
    }

    history.push(client_path.dotationList);
  }

  useEffect(() => {
    countryService.getCountries().then((result) => {
      setCountries(result);
      const countriesRequest = result;
      projectService.getProjectLightModel().then((result) => {
        setProjects(result);
        costService.getCostTypes().then((result) => {
          const costTypesRequest = result;
          setCostTypes(result);
          dotationPersonService.getDotationPersons().then((result) => {
            const dotationPersonRequest = result;
            setDotationPersons(result);
            if (isNew === false) {
              dotationService.getById(dotationId).then((item) => {
                if (item) {
                  setNumber(item.number);
                  setCity(item.city);
                  setAmount(item.amount);
                  setAmountCurrency(item.amountCurrency);
                  setSettlementDate(item.settlementDate);
                  setDotationDate(item.dotationDate);
                  if (item.documentScanDate) {
                    setDocumentScanDate(item.documentScanDate);
                  }

                  if (item.documentOriginalDate) {
                    setDocumentOriginalDate(item.documentOriginalDate);
                  }

                  setPurpose(item.purpose);
                  setDescription(item.description);
                  setProject(item.projectId);
                  setCountry(item.countryId);
                  setCostTypeId(item.costTypeId);
                  setDotationPerson(item.dotationPersonId);
                  setHasPhotographicDocumentation(
                    item.hasPhotographicDocumentation
                  );
                  setIsLoaded(true);
                }
              });
            } else {
              dictionary_service
                .getDotationDefaultProject()
                .then((defaultProject) => {
                  setProject(defaultProject.id);
                  setCountry(countriesRequest[0].countryId);
                  setDotationPerson(dotationPersonRequest[0].personId);
                  setCostTypeId(costTypesRequest[0].id);
                  setIsLoaded(true);
                });
            }
          });
        });
      });
    });
  }, []);

  const dateFormat = "dd.MM.yyyy";
  const renderPart = !isLoaded ? (
    <Spinner></Spinner>
  ) : (
    <div className="edit-form-box">
      <MDBJumbotron style={{ width: "800px" }}>
        <form onSubmit={save}>
          <h1 className="text-center">Dotacja</h1>
          <MDBRow>
            <MDBCol md="6" className="mb-6">
              <label htmlFor="txtNumber" className="gray-text">
                Numer dotacji
              </label>
              <div>
                <input
                  type="text"
                  onChange={(e) => setNumber(e.target.value)}
                  className="form-control form-control-sm"
                  value={number}
                  id="txtName"
                  required
                />
              </div>
            </MDBCol>
            <MDBCol md="6">
              <label htmlFor="cbxCostType" className="gray-text">
                Rodzaj kosztu
              </label>
              <select
                id="cbxCostType"
                value={costTypeId}
                onChange={(e) => setCostTypeId(e.target.value)}
                className="browser-default custom-select"
              >
                {costTypes.map((costType) => {
                  return (
                    <option value={costType.id} key={costType.id}>
                      {costType.name}
                    </option>
                  );
                })}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" className="mb-6">
              <label htmlFor="cbxCountry" className="gray-text">
                Państwo
              </label>
              <select
                id="cbxCountry"
                value={countryId}
                onChange={(e) => setCountry(e.target.value)}
                className="browser-default custom-select"
              >
                {countries.map((country) => {
                  return (
                    <option value={country.countryId} key={country.countryId}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </MDBCol>
            <MDBCol md="6" className="mb-6">
              <label htmlFor="txtCity" className="gray-text">
                Misja (miasto)
              </label>
              <input
                type="text"
                onChange={(e) => setCity(e.target.value)}
                className="form-control form-control-sm"
                value={city}
                id="txtName"
                required
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" className="mb-6">
              <label htmlFor="cbxSubsidyPersons" className="gray-text">
                Odbiorca dotacji
              </label>
              <select
                id="cbxSubsidyPersons"
                value={dotationPersonId}
                onChange={(e) => setDotationPerson(e.target.value)}
                className="browser-default custom-select"
              >
                {dotationPersons.map((person) => {
                  return (
                    <option value={person.personId} key={person.personId}>
                      {person.displayName}
                    </option>
                  );
                })}
              </select>
            </MDBCol>
            <MDBCol md="6" className="mb-6">
              <label htmlFor="cbxProjects" className="gray-text">
                Subkonto
              </label>
              <select
                id="cbxProjects"
                value={projectId}
                onChange={(e) => setProject(e.target.value)}
                className="browser-default custom-select"
              >
                {projects.map((project) => {
                  return (
                    <option value={project.projectId} key={project.projectId}>
                      {project.name}
                    </option>
                  );
                })}
              </select>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" className="mb-3">
              <label htmlFor="txtAmount" className="gray-text">
                Kwota (PLN)
              </label>
              <input
                type="text"
                pattern="[0-9]+([\.,][0-9]+[0-9])?"
                name="txtAmount"
                value={amount}
                className="form-control form-control-sm"
                onChange={(e) => setAmount(e.target.value)}
                id="txtAmount"
                required
              />
            </MDBCol>
            <MDBCol md="6" className="mb-3">
              <label htmlFor="txtAmountCurrency" className="gray-text">
                Waluta (uwagi)
              </label>
              <input
                type="text"
                name="txtAmountCurrency"
                value={amountCurrency}
                className="form-control form-control-sm"
                onChange={(e) => setAmountCurrency(e.target.value)}
                id="txtAmount"
                required
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="3">
              <label htmlFor="dtDotationDate" className="gray-text">
                Data dotacji
              </label>

              <DayPickerInput
                name="dtDotationDate"
                format={dateFormat}
                formatDate={formatDate}
                onDayChange={(e) => setDotationDate(e)}
                value={dotationDate}
                className="form-control form-control-sm"
                id="dtDotationDate"
                required
              />
            </MDBCol>
            <MDBCol md="3">
              <label htmlFor="dtSetSettlementDate" className="gray-text">
                Termin rozliczenia
              </label>

              <DayPickerInput
                name="dtSetSettlementDate"
                format={dateFormat}
                formatDate={formatDate}
                onDayChange={(e) => setSettlementDate(e)}
                value={settlementDate}
                className="form-control form-control-sm"
                id="dtSetSettlementDate"
                required
              />
            </MDBCol>
            <MDBCol md="3">
              <label htmlFor="dtDocumentScanDate" className="gray-text">
                Data skanu
              </label>

              <DayPickerInput
                name="dtDocumentScanDate"
                format={dateFormat}
                formatDate={formatDate}
                onDayChange={(e) => setDocumentScanDate(e)}
                value={documentScanDate}
                className="form-control form-control-sm"
                id="dtDocumentScanDate"
                required
              />
            </MDBCol>
            <MDBCol md="3">
              <label htmlFor="dtDocumentOriginalDate" className="gray-text">
                Data oryginału
              </label>

              <DayPickerInput
                name="dtDocumentOriginalDate"
                format={dateFormat}
                formatDate={formatDate}
                onDayChange={(e) => setDocumentOriginalDate(e)}
                value={documentOriginalDate}
                className="form-control form-control-sm"
                id="dtDocumentOriginalDate"
                required
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="6" className="mb-6">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={hasPhotographicDocumentation}
                    onChange={handleCheckBoxChange}
                    name="hasPhotographicDocumentation"
                    color="primary"
                  />
                }
                label="Dokumentacja fotograficzna"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="12">
              <label htmlFor="txtPurpose" className="gray-text">
                Cel dotacji
              </label>
              <textarea
                rows="4"
                onChange={(e) => setPurpose(e.target.value)}
                className="form-control form-control-sm"
                value={purpose}
                id="txtPurpose"
                data-name="purpose"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md="12">
              <label htmlFor="txtDescription" className="gray-text">
                Uwagi
              </label>
              <textarea
                rows="3"
                onChange={(e) => setDescription(e.target.value)}
                className="form-control form-control-sm"
                value={description}
                id="txtDescription"
                data-name="description"
              />
            </MDBCol>
          </MDBRow>
          <div className="text-center">
            <button className="btn btn-white" onClick={handleCancel}>
              Anuluj
            </button>
            <button className="btn btn-primary" type="submit">
              Zapisz
            </button>
          </div>
        </form>
      </MDBJumbotron>
    </div>
  );
  return <>{renderPart}</>;
};

export default DotationEdit;
