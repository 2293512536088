import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Spinner from '../../common components/spinner/spinner';
import projectService from "./projectService";
import client_path from '../../../constants/client_URL_paths';
import SummaryTable from '../../table/summaryTable'
import './project.css';

class ProjectGrantDetail extends Component {
    state = {
        data: [],
        columns: [
            { header: "Data", field: 'incomeDate', type: 'date', orderType: 'date' },
            { header: "Konto bankowe", field: 'bankAccountName', sort: 'asc', width: 200 },
            { header: "Darczyńca", field: 'donorDisplayMember', sort: 'asc', width: 450 },
            { header: "Kwota wpłaty", field: 'incomeAmount', isSummary: true, type: 'currency' },
            { header: "Uwagi", field: 'description', sort: 'asc', width: 200 }
        ],
        quarterAmounts: null,
        isLoaded: false
    }

    async componentDidMount() {
        var projectId = this.props.projectId;

        const items = await projectService.getProjectGrants(projectId);
        const quarterAmount = await projectService.getQuarterGrants(projectId);

        const data = items.map((grant, index) => {
            return {
                id: grant.id,
                incomeDate: grant.incomeDate,
                projectName: grant.projectName,
                bankAccountName: grant.bankAccountName,
                donorDisplayMember: grant.donorDisplayMember,
                incomeAmount: grant.incomeAmount,
                description: grant.description,
                actions: <span className="editButton" onClick={() => this.redirectToGrantEditForm(grant)}>Edytuj</span>
            }
        })

        this.setState({ data, isLoaded: true, quarterAmounts: quarterAmount })
    }

    redirectToGrantEditForm(grant) {
        this.props.history.push(`${client_path.grantEditForm}/${grant.id}`)
    }

    render() {
        const { columns, data, isLoaded, quarterAmounts } = this.state;

        var renderedPart = !isLoaded ? <Spinner /> :
            <div>

                <div>
                    <table className="summaryTable">
                        <thead>
                            <tr >
                                <th className="summaryCellHeader">Kwartał</th>
                                <th className="summaryCellHeader">Suma wpłat</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="summaryCellData">I</td>
                                <td className="summaryCellData"> {quarterAmounts.quarterFirst} zł </td>
                            </tr>
                            <tr>
                                <td className="summaryCellData">II</td>
                                <td className="summaryCellData"> {quarterAmounts.quarterSecond} zł </td>
                            </tr>
                            <tr>
                                <td className="summaryCellData">III</td>
                                <td className="summaryCellData"> {quarterAmounts.quarterThird} zł </td>
                            </tr>
                            <tr>
                                <td className="summaryCellData">IV</td>
                                <td className="summaryCellData"> {quarterAmounts.quarterFour} zł </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

               <SummaryTable
                   columns={columns}
                    defaultOrderBy="incomeDate"
                    defaultOrder="desc"
                    data={data} 
               />

            </div>

        return (
            <>
                {renderedPart}
            </>
        );
    }
}

export default withRouter(ProjectGrantDetail);
