import { MDBRow, MDBCol } from "mdbreact";
import "react-datepicker/dist/react-datepicker.css";

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import client_path from "../../../constants/client_URL_paths";
import Spinner from "../../common components/spinner/spinner";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import "./cost.css";

import bankAccountService from "../bankAccount/bankAccountService";
import dictionary_service from "../../../common-services/dictionaryService";
import editForm_service from "./costService";

import { DateUtils } from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";

import "react-day-picker/lib/style.css";

class CostEdit extends Component {
  state = {
    costInfo: {
      costDate: new Date(),
      projectId: "",
      bankAccountId: "",
      costTypeId: "",
      amount: 0,
      documentNumber: "",
      description: "",
      note: "",
      isCash: false,
    },

    projectSuggestions: [],
    costTypes: [],
    loadedData: false,
  };

  async componentDidMount() {
    try {
      const projectSuggestionItems =
        await dictionary_service.getProjectSuggestions();
      const defaultBankAccount =
        await bankAccountService.getDefaultBankAccount();
      const defaultProject = await dictionary_service.getCostDefaultProject();
      const defaultCostType = await editForm_service.getDefaultCostType();
      const costTypes = await editForm_service.getCostTypes();
      let defaultProjectId = 0;
      if (defaultProject) {
        defaultProjectId = defaultProject.id;
      }

      if (
        defaultProjectId === 0 &&
        projectSuggestionItems !== null &&
        projectSuggestionItems.length !== 0
      ) {
        defaultProjectId = projectSuggestionItems[0].id;
      }

      const costId = this.props.match.params.id;

      if (costId === "createCost") {
        const { costInfo } = this.state;

        if (defaultBankAccount !== null) {
          costInfo.bankAccountId = defaultBankAccount.bankAccountId;
        }

        costInfo.projectId = defaultProjectId;
        costInfo.costTypeId = defaultCostType.id;
        this.setState({
          loadedData: true,
          projectSuggestions: projectSuggestionItems,
          costTypes: costTypes,
          costInfo: costInfo,
        });

        return;
      }

      const foundCost = await editForm_service.getCostById(costId);

      if (foundCost.costTypeId === "00000000-0000-0000-0000-000000000000") {
        foundCost.costTypeId = defaultCostType.id;
      }

      var reversDate = foundCost.costDate.split(".").reverse().join("-");
      foundCost.costDate = new Date(reversDate);

      this.setState({
        costInfo: foundCost,
        costTypes: costTypes,
        projectSuggestions: projectSuggestionItems,
        loadedData: true,
      });
    } catch (ex) {
      throw Error(ex);
    }
  }

  handleCancel = () => {
    this.props.history.push(client_path.costList);
  };

  submitHanlder = (event) => {
    event.target.className += " was-validated";
    event.preventDefault();

    if (this.isValid()) {
      try {
        const { costInfo } = this.state;
        const costId = this.props.match.params.id;

        if (costId === "createCost") {
          editForm_service.create(costInfo).then(() => {
            this.props.history.push(client_path.costList);
          });
        } else {
          editForm_service.update(costInfo).then(() => {
            this.props.history.push(client_path.costList);
          });
        }
      } catch (ex) {
        console.log("error", ex);
      }
    }
  };

  isValid() {
    const { costInfo } = this.state;

    if (
      costInfo.amount === "" ||
      costInfo.amount === "0" ||
      costInfo.amount === 0
    ) {
      return false;
    }

    if (costInfo.documentNumber === null || costInfo.documentNumber === "") {
      return false;
    }

    if (costInfo.description === null || costInfo.description === "") {
      return false;
    }

    costInfo.amount = costInfo.amount.toString().replace(",", ".");
    return true;
  }

  formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  handleCheckBoxChange = (event) => {
    const { costInfo } = this.state;
    costInfo.isCash = event.target.checked;

    this.setState({ costInfo });
  };

  parseDate(str, format, locale) {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  onDayChange = (date) => {
    const { costInfo } = this.state;
    costInfo.costDate = date;
    this.setState({ costInfo });
  };

  handleInputOnChange = ({ currentTarget }) => {
    const property = currentTarget.getAttribute("name");
    const { costInfo } = this.state;
    costInfo[property] = currentTarget.value;
    this.setState({ costInfo });
  };

  handleInputOnProjectChange = (event) => {
    const { costInfo } = this.state;
    costInfo.projectId = event.target.value;
    this.setState({ costInfo });
  };

  handleInputOnCostTypeChange = (event) => {
    const { costInfo } = this.state;
    costInfo.costTypeId = event.target.value;
    this.setState({ costInfo });
  };

  render() {
    const { projectSuggestions, costTypes, costInfo, loadedData } = this.state;

    const dateFormat = "dd.MM.yyyy";
    return (
      <div>
        <form
          className="needs-validation"
          onSubmit={this.submitHanlder.bind(this)}
          noValidate
        >
          <h1 className="text-center">Koszt</h1>
          {!loadedData ? (
            <Spinner />
          ) : (
            <div>
              <MDBRow>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="dtDateInput" className="gray-text">
                    Data
                  </label>
                  <div>
                    <DayPickerInput
                      name="costDate"
                      format={dateFormat}
                      formatDate={this.formatDate}
                      onDayChange={this.onDayChange}
                      value={costInfo.costDate}
                      className="form-control form-control-sm"
                      id="dtDateInput"
                      required
                    />
                  </div>
                </MDBCol>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtDocumentNumber" className="gray-text">
                    Numer rachunku / dotacji
                  </label>
                  <input
                    type="text"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={costInfo.documentNumber}
                    id="txtDocumentNumber"
                    name="documentNumber"
                    required
                  />
                </MDBCol>
                <MDBCol md="4" className="mb-3">
                  <label htmlFor="txtAmount" className="gray-text">
                    Kwota
                  </label>
                  <input
                    type="text"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={costInfo.amount}
                    id="txtAmount"
                    name="amount"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="txtDescription" className="gray-text">
                    Adresat / opis
                  </label>
                  <textarea
                    rows="3"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={costInfo.description}
                    id="txtDescription"
                    name="description"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="cbxProjects" className="gray-text">
                    Subkonto
                  </label>
                  <select
                    id="cbxProjects"
                    className="browser-default custom-select"
                    onChange={this.handleInputOnProjectChange}
                    value={costInfo.projectId}
                    required
                  >
                    {projectSuggestions.map((project) => {
                      return (
                        <option value={project.id} key={project.id}>
                          {project.displayMember}
                        </option>
                      );
                    })}
                  </select>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="cbxCostTypes" className="gray-text">
                    Rodzaj kosztu
                  </label>
                  <select
                    id="cbxCostTypes"
                    className="browser-default custom-select"
                    value={costInfo.costTypeId}
                    onChange={this.handleInputOnCostTypeChange}
                  >
                    {costTypes.map((costType) => {
                      return (
                        <option value={costType.id} key={costType.id}>
                          {costType.name}
                        </option>
                      );
                    })}
                  </select>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="12" className="mb-3">
                  <label htmlFor="txtNote" className="gray-text">
                    Uwagi
                  </label>
                  <textarea
                    rows="3"
                    onChange={this.handleInputOnChange}
                    className="form-control form-control-sm"
                    value={costInfo.note}
                    id="txtNote"
                    name="note"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol md="6" className="mb-6">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={costInfo.isCash}
                        onChange={this.handleCheckBoxChange}
                        name="isCash"
                        color="primary"
                      />
                    }
                    label="Raport kasowy / gotówka"
                  />
                </MDBCol>
              </MDBRow>
              <div className="text-center">
                <button className="btn btn-white" onClick={this.handleCancel}>
                  Odrzuć
                </button>
                <button className="btn btn-primary" type="submit">
                  Zapisz
                </button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default withRouter(CostEdit);
