import React, { Component } from 'react';
import { MDBContainer } from 'mdbreact';
import DonorDetailTable from '../../features/donorTable/donorDetailTable';
import GrantSheetMenu from '../menu/menuNavBar';

class DonorDetailContainer extends Component {
    state = {}

    render() {
        return (
            <div>
                <GrantSheetMenu />

                <MDBContainer fluid>
                    <DonorDetailTable />
                </MDBContainer>

            </div>
        );
    }
}

export default DonorDetailContainer;