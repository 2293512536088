export default {
  auth: "/user/authenticate",
  users: "/users",
  donor: {
    getDonors: "/donor/getdonors",
    getdonor: "donor/getdonor",
    createDonor: "/donor/create",
    updateDonor: "/donor/update",
    deleteDonor: "/donor/delete",
  },
  projects: {
    getprojects: "/project/getprojects",
    getProjectById: "/project/getProjectById",
    createProject: "/project/create",
    updateProject: "/project/update",
    getProjectGrants: "/project/getProjectGrants",
    getProjectCosts: "/project/getProjectCosts",
    deleteProjectCost: "/project/deleteProjectCost",
    getProjectCostById: "/project/getProjectCostById",
    updateProjectCost: "/project/updateProjectCost",
    createProjectCost: "/project/createProjectCost",
    getQuarterGrants: "/project/getQuarterGrants",
    getDefaultProject: "/project/getDefaultProject",
    getGrantDefaultProject: "project/getGrantDefaultProject",
    getCostDefaultProject: "project/getCostDefaultProject",
    getDotationDefaultProject: "project/getDotationDefaultProject",
    getProjectsByRange: "/project/getProjects",
  },
  bankAccount: {
    getBankAccounts: "/bankAccount/getbankaccounts",
    getDefaultBankAccount: "/bankAccount/getDefaultBankAccount",
    getBankAccount: "/bankAccount/GetBankAccountById",
    createBankAccount: "/bankAccount/create",
    updateBankAccount: "/bankAccount/update",
  },
  grant: {
    getGrants: "/grant/getGrants",
    getDonorGrants: "/grant/getDonorGrants",
    getGrantById: "/grant/getGrantById",
    createGrant: "/grant/create",
    updateGrant: "/grant/update",
  },
  cost: {
    getCosts: "/cost/getCosts",
    getProjectCosts: "/cost/getProjectCosts",
    getCostById: "/cost/getCostById",
    create: "/cost/create",
    update: "/cost/update",
    delete: "/cost/delete",
    getCostTypes: "/cost/getCostTypes",
    getDefaultCostType: "/cost/getDefaultCostType",
  },
  dictionary: {
    getDonorSuggestions: "/grant/getDonorSuggestions",
    getBankAccountSuggestions: "/grant/getBankAccountSuggestions",
    getProjectSuggestions: "/grant/getProjectSuggestions",
  },
  user: {
    logout: "/user/logout",
    getLoggedUser: "/user/getLoggedUser",
  },
  balance: {
    getBalances: "/balance/getBalances",
    getBalanceById: "/balance/getBalanceById",
    getBalanceAnalyses: "/balance/getBalanceAnalyses",
    update: "/balance/update",
    create: "/balance/create",
  },
  quarterSettlements: {
    getQuarterSettlements: "/quarterlySettlements/getQuarterSettlements",
    applyQuarterSettlements: "/quarterlySettlements/applyQuarterSettlements",
    getCurrentQuarterQuarterSettlements:
      "/quarterlySettlements/getCurrentQuarterSettlements",
  },
  transfers: {
    getTransfers: "/transfers/getTransfers",
    getTransfer: "/transfers/getTransfer",
    create: "/transfers/create",
    update: "/transfers/update",
    delete: "/transfers/delete",
  },
  cashReport: {
    getCashReport: "/cashreport/getCashReport",
    exportToExcel: "/cashreport/exportToExcel",
  },
  dotations: {
    create: "/dotation/create",
    getById: "/dotation/getById",
    search: "/dotation/search",
    update: "/dotation/udate",
    delete: "/dotation/delete",
  },
  country: {
    search: "/country/search",
  },
};
