import httpService from "./httpService";
import server_path from '../constants/server_URL_paths';

const tokenKey = "grandsheetToken";

export async function login(login, password) {
    const { data: response } = await httpService.post(server_path.auth, { login, password });
    return response;
}

export function setTokenToLocalStorage(token) {
    return localStorage.setItem(tokenKey, token);
}

export function getTokenFromLocalStorage() {
    return localStorage.getItem(tokenKey);
}

export function removeTokenFromLocalStorage() {
    localStorage.removeItem(tokenKey);

}

export default {
    login,
    setTokenToLocalStorage,
    getTokenFromLocalStorage,
    removeTokenFromLocalStorage
}