import httpService from "../../../common-services/httpService";
import server_path from "../../../constants/server_URL_paths";
import axios from "axios";

export async function getCashReport(from, to) {
  const dateFrom = `${from.getFullYear()}-${
    from.getMonth() + 1
  }-${from.getDate()}`;
  const dateTo = `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`;

  const requestUrl = `${server_path.cashReport.getCashReport}?from=${dateFrom}&to=${dateTo}`;
  const { data } = await httpService.get(requestUrl);
  return data;
}

export async function exportToExcel(request) {
  const config = { responseType: "blob" };
  const response = await httpService.post(
    `${server_path.cashReport.exportToExcel}`,
    request,
    config
  );

  const reportDate = new Date(request.reportDate);
  const fileName = `raport_kasowy_${
    reportDate.getMonth() + 1
  }_${reportDate.getFullYear()}.xlsx`;

  const url = URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
}

export default {
  getCashReport,
  exportToExcel,
};
