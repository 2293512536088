import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import transferService from '../services/transferService';
import SummaryTable from '../../../table/summaryTable';
import Spinner from '../../../common components/spinner/spinner';
import client_path from '../../../../constants/client_URL_paths';
import TransferCreateButton from './transfer-create-button';
import { MDBContainer, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn } from 'mdbreact';

export class Transfers extends Component {
    state = {
        data: [],
        columns: [
            { header: "Data", field: 'transferDate', type: 'date', orderType: 'date'},
            { header: "Transfer z subkonta", field: 'sourceProjectName' },
            { header: "Transfer na subkonto", field: 'targetProjectName' },
            { header: "Kwota", field: 'amount', isSummary: true, type: 'currency' },
            { header: "", field: 'actions' },
        ],
        confirmDelete: false,
        transferToDelete: null,
        isLoaded: false
    }

    async componentDidMount() {
        var transferItems = await transferService.getTransfers();
        const data = transferItems.map(transfer => {
            return {
                id: transfer.id,
                sourceProjectId: transfer.sourceProjectId,
                sourceProjectName: transfer.sourceProjectName,
                targetProjectId: transfer.targetProjectId,
                targetProjectName: transfer.targetProjectName,
                amount: transfer.amount,
                createDate: transfer.createDate,
                transferDate: transfer.transferDate,
                actions: <> 
                            <span className="editButton" onClick={() => this.redirectToEdit(transfer)}>Edytuj</span> | 
                            <span className="editButton" onClick={() => this.confirmDelete(transfer)}> Usuń</span>
                        </> 
            }
        });

        this.setState({ data, isLoaded: true })
    }

    confirmDelete(transfer) {

        this.setState({ transferToDelete: transfer, confirmDelete: true });
    }

    cancelDelete = () => {
        this.setState({
            confirmDelete: !this.state.confirmDelete
        });
    }

    deleteTransfer() {
        const { transferToDelete } = this.state;
        transferService.deleteTransfer(transferToDelete.id).then(()=>{
            window.location.reload();
        });
        
    }

    render() {
        const { columns, data, isLoaded, confirmDelete } = this.state;
        var deleteModal = "";

        if (confirmDelete) {
            deleteModal = <MDBContainer>
                <MDBModal isOpen={this.state.confirmDelete} toggle={this.cancelDelete}>
                    <MDBModalHeader toggle={this.cancelDelete}>Usuwanie transferu</MDBModalHeader>
                    <MDBModalBody>
                        Czy na pewno chcesz usunąć wybrany transfer wewnętrzny  ?
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="light" onClick={this.cancelDelete}>Nie</MDBBtn>
                        <MDBBtn color="primary" onClick={this.deleteTransfer.bind(this)}>Tak</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        }

        var renderedPart = !isLoaded ? <Spinner /> :
            <div>
                <div className="d-flex justify-content-between">
                    <h3 className="text-center pt-2">Transfery wewnętrzne</h3>
                    <TransferCreateButton />
                </div>
                <SummaryTable 
                    columns={columns}
                    defaultOrderBy="transferDate"
                    defaultOrder="desc"
                    data={data} />
                {deleteModal}
            </div>

        return (
            <>
                {renderedPart}
            </>
        );
    }

    redirectToEdit(transfer) {
        this.props.history.push(`${client_path.transferEditPage}/${transfer.id}`)
    }
}

export default withRouter(Transfers);