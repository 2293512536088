import React, { Component } from "react";
import projectService from "./projectService";
import { withRouter } from "react-router-dom";
import Spinner from "../../common components/spinner/spinner";
import client_path from "../../../constants/client_URL_paths";
import AddProjectButton from "../../features/project/addProject";
import SummaryTable from "../../table/summaryTable";

import "./project.css";

class ProjectTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      columns: [
        { header: "Nazwa subkonta", field: "name", sort: "asc" },
        {
          header: "Saldo",
          field: "balance",
          isSummary: true,
          type: "currency",
          isBold: true,
        },
        {
          header: "Przychody",
          field: "totalIncome",
          isSummary: true,
          type: "currency",
        },
        {
          header: "Koszty",
          field: "totalCost",
          isSummary: true,
          type: "currency",
        },
        { header: "Prowizja - 1", field: "percentage", type: "percent" },
        { header: "Prowizja - 2", field: "secondPercentage", type: "percent" },
        { header: "Aktywny", field: "isActive", type: "bool" },
        { header: "", field: "actions" },
      ],

      isLoaded: false,
    };

    this.searchProjectsCallback = this.searchProjectsCallback.bind(this);
  }

  async componentDidMount() {
    var today = new Date();
    var dateFrom = new Date(today.getFullYear(), 0, 1);
    var dateTo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    this.searchProjects(dateFrom, dateTo);
  }

  searchProjectsCallback(from, to) {
    var dateFrom = new Date(from);
    var dateTo = new Date(to);

    this.searchProjects(dateFrom, dateTo);
  }

  async searchProjects(from, to) {
    var request = {
      dateFrom: from,
      dateTo: to,
    };

    const projects = await projectService.getProjectsByRange(request);
    const data = projects.map((project) => {
      return {
        id: project.id,
        name: project.name,
        totalIncome: project.totalIncome,
        totalCost: project.totalCost,
        balance: project.balance,
        percentage: project.percentage,
        secondPercentage: project.secondPercentage,
        isActive: project.isActive,
        actions: (
          <>
            <span
              className="editButton"
              onClick={() => this.redirectToProjectDetails(project)}
            >
              Szczegóły |{" "}
            </span>
            <span
              className="editButton"
              onClick={() => this.redirectToProjectEditForm(project)}
            >
              Edytuj
            </span>
          </>
        ),
      };
    });

    this.setState({ data, isLoaded: true });
  }

  redirectToProjectEditForm(project) {
    this.props.history.push(`${client_path.projectEditForm}/${project.id}`);
  }

  redirectToProjectDetails(project) {
    this.props.history.push(`${client_path.projectBalanceList}/${project.id}`);
  }

  render() {
    const { data, isLoaded, columns } = this.state;
    var renderedPart = !isLoaded ? (
      <Spinner />
    ) : (
      <div>
        <div className="d-flex justify-content-between">
          <h3 className="text-center pt-2">Lista subkont</h3>
          <AddProjectButton />
        </div>
        <SummaryTable
          columns={columns}
          data={data}
          searchItemCallback={this.searchProjectsCallback}
          showDateRangeSearch={true}
          defaultOrderBy="name"
          defaultOrder="asc"
        />
      </div>
    );

    return <>{renderedPart}</>;
  }
}

export default withRouter(ProjectTable);
