import httpService from "../../../common-services/httpService";
import server_path from "../../../constants/server_URL_paths";

export async function getCountries() {
  const { data } = await httpService.get(`${server_path.country.search}`);
  return data;
}

export default {
  getCountries,
};
