import httpService from "../../../common-services/httpService";
import server_path from '../../../constants/server_URL_paths';

export async function getGrants(grantRequest) {
    const { data } = await httpService.post(`${server_path.grant.getGrants}`, grantRequest);
    return data;
}

export async function getDonorGrants(donorId) {
    const { data } = await httpService.get(`${server_path.grant.getDonorGrants}?donorId=${donorId}`);
    return data;
}

export async function getGrantById(grantId) {
    const { data: grant } = await httpService.get(`${server_path.grant.getGrantById}?grantId=${grantId}`);
    return grant;
}

export async function updateGrant(grant) {
    const { data: foundGrant } = await httpService.put(`${server_path.grant.updateGrant}`, grant);
    return foundGrant;
}

export async function createGrant(grant) {
    const { data: foundGrant } = await httpService.post(`${server_path.grant.createGrant}`, grant);
    return foundGrant;
}
export default {
    getGrants,
    getDonorGrants,
    getGrantById,
    updateGrant,
    createGrant
}
