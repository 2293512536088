import React, { Component } from 'react';
import { MDBContainer } from 'mdbreact';
import DonorTable from '../../features/donorTable/donorTable';
import GrantSheetMenu from '../menu/menuNavBar';
import "./donorList.css";

class DonorList extends Component {
    state = {}

    render() {
        return (
            <div>
                <GrantSheetMenu />

                <MDBContainer fluid>
                    <DonorTable />
                </MDBContainer>

            </div>
        );
    }
}

export default DonorList;