import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';
import SummaryTable from '../../table/summaryTable'
import BalanceAnalysisSummaryTable from '../balance/balanceAnalysisSummaryTable';
import balanceService from '../balance/balanceService';
import projectService from '../project/projectService';
import './project.css';

class ProjectSummaryDetail extends Component {
    state = {
        data: [],
        balanceItem: null,
        projectName: "",
        columns: [
            { header: "Data", field: 'date', type: 'date', orderType: 'date' },
            { header: "Nazwa / opis", field: 'displayText', },
            { header: "Kwota", field: 'amount', isSummary: true, type: 'currency' }
        ],

        isLoaded: false
    }

    async componentDidMount() {
        var projectId = this.props.projectId;
        var project = await projectService.getProjectById(projectId);

        balanceService.getBalanceAnalyses(projectId).then(balanceItem => {

            const data = balanceItem.items.map((item, index) => {
                return {
                    id: index + 1,    
                    date: item.date,
                    displayText: item.displayText,
                    amount: item.amount
                }
            });

            this.setState({ data, balanceItem, projectName: project.name });
        });
    }

    render() {
        const { columns, data, balanceItem } = this.state;
        
        return (
            <MDBContainer fluid>
                <div>
                    <BalanceAnalysisSummaryTable balanceItem={balanceItem} />
                </div>
                <SummaryTable
                   columns={columns}
                    defaultOrderBy="date"
                    defaultOrder="desc"
                    data={data} />
            </MDBContainer>
        );
    }
}

export default withRouter(ProjectSummaryDetail);