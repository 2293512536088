import { get } from "lodash";
import { MDBInput } from 'mdbreact';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { withRouter } from 'react-router-dom';
import client_path from '../../../constants/client_URL_paths';
import Spinner from '../../common components/spinner/spinner';
import "./balance.css";
import dictionary_service from '../../../common-services/dictionaryService';
import editForm_service from "./balanceService";

class BalanceEdit extends Component {

    state = {
        balanceInfo: {
            projectId: "",
            openDate: new Date(2020, 0, 1),
            openAmount: 0,
            closeDate: null,
            closeAmount: 0,
            openDateValue: ""
        },

        projectSuggestions: [],

        inputs: [
            { label: "Kwota otwarcia", property: "openAmount" },
            { label: "Kwota zamknięcia ", property: "closeAmount" }
        ],

        loadedData: false
    }


    async componentDidMount() {
        try {

            const projectSuggestionItems = await dictionary_service.getProjectSuggestions();

            const balanceId = this.props.match.params.id;
            if (balanceId === "createBalance") {
                this.setState(
                    {
                        loadedData: true,
                        projectSuggestions: projectSuggestionItems
                    })
                return
            }

            const foundBalance = await editForm_service.getBalanceById(balanceId)
            foundBalance.openDate = new Date(foundBalance.openDateValue);

            if (foundBalance.closeDateValue !== null) {
                foundBalance.closeDate = new Date(foundBalance.closeDateValue);
            }

            this.setState(
                {
                    balanceInfo: foundBalance,
                    loadedData: true,
                    projectSuggestions: projectSuggestionItems
                })
        }
        catch (ex) {
            throw Error(ex)
        }
    }

    handleCancel = () => {
        this.props.history.push(client_path.balanceList)
    }

    handleSave = async () => {
        try {
            const { balanceInfo } = this.state;

            const balanceId = this.props.match.params.id;

            if (balanceId === "createBalance") {
                await editForm_service.create(balanceInfo);
            }
            else {
                await editForm_service.update(balanceInfo);
            }

            this.props.history.push(client_path.balanceList)
        }
        catch (ex) {
            console.log('error', ex)
        }
    }

    handleInputOnChange = ({ currentTarget }) => {
        const property = currentTarget.getAttribute('data-name');
        const { balanceInfo } = this.state;
        balanceInfo[property] = currentTarget.value;
        this.setState({ balanceInfo })
    }

    onChange = date => {
        const { balanceInfo } = this.state;
        balanceInfo.openDate = date;
        this.setState({ balanceInfo })
    }

    onChange2 = date => {
        const { balanceInfo } = this.state;
        balanceInfo.closeDate = date;
        this.setState({ balanceInfo })
    }

    onProjectChange = (event) => {
        const { balanceInfo } = this.state;
        balanceInfo.projectId = event.target.value;
        this.setState({ balanceInfo })
    }

    render() {
        const {
            projectSuggestions,
            balanceInfo,
            inputs,
            loadedData
        } = this.state;

        return (
            <div className="editForm">
                <h1 className="text-center">Bilans</h1>
                {!loadedData ? <Spinner /> : <div className="edit-form__input-box">
                    <div>
                        Projekt:
                        <select className="browser-default custom-select" onChange={this.onProjectChange} value={balanceInfo.projectId}>
                            {projectSuggestions.map(project => {
                                return (
                                    <option value={project.id} key={project.id}>{project.displayMember}</option>
                                )
                            })}
                        </select>

                    </div>
                    <div>
                        Data otwarcia:
                          <div>
                            <DatePicker
                                onChange={this.onChange}
                                selected={balanceInfo.openDate}>
                            </DatePicker>
                        </div>
                    </div>
                    <div>
                        <MDBInput
                            label={inputs[0].label}
                            value={get(balanceInfo, inputs[0].property)}
                            data-name={inputs[0].property}
                            onChange={this.handleInputOnChange}
                        />
                    </div>
                    <div>
                        Data zamknięcia:
                          <div>
                            <DatePicker
                                onChange={this.onChange2}
                                selected={balanceInfo.closeDate}>
                            </DatePicker>
                        </div>
                    </div>
                    <div>
                        <MDBInput
                            label={inputs[1].label}
                            value={get(balanceInfo, inputs[1].property)}
                            data-name={inputs[1].property}
                            onChange={this.handleInputOnChange}
                        />
                    </div>
                    <div className="text-center">
                        <button
                            className="btn btn-white"
                            onClick={this.handleCancel}
                        >Odrzuć
                            </button>
                        <button
                            className="btn btn-primary"
                            onClick={this.handleSave}
                        >Zapisz
                            </button>
                    </div>

                </div>}

            </div>
        );
    }
}

export default withRouter(BalanceEdit);
