import React from "react";
import { withRouter } from "react-router-dom";
import { MDBBtn } from "mdbreact";
import client_paths from "../../../constants/client_URL_paths";

const AddDotationButton = (props) => {
  const handleAddDotation = () => {
    props.history.push(`${client_paths.dotationEdit}/0`);
  };

  return (
    <MDBBtn size="sm" onClick={() => handleAddDotation()}>
      Nowa dotacja
    </MDBBtn>
  );
};

export default withRouter(AddDotationButton);
