import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GrantSheetMenu from "../menu/menuNavBar";
import { MDBContainer } from "mdbreact";
import DotationTable from "../../features/dotation/dotationTable";
import dotationService from "../../features/dotation/dotation.service";
import client_path from "../../../constants/client_URL_paths";

const DotationList = () => {
  const [items, setItems] = useState([]);
  const history = useHistory();

  useEffect(() => {
    var request = {
      year: 2021,
    };
    dotationService.search(request).then((result) => {
      var dataSource = result.map((x) => {
        return {
          dotationWithNumber: (
            <span>
              {x.dotationDate}
              <br />
              {x.number}
            </span>
          ),
          id: x.dotationId,
          countryWithCity: (
            <span>
              {x.countryName}
              <br />
              {x.city}
            </span>
          ),
          amountWithCurrency: (
            <span>
              {x.amount}
              <br />
              {x.amountCurrency}
            </span>
          ),
          dotationPersonName: x.dotationPersonName,
          projectName: x.projectName,
          description: x.description,
          settlementDate: x.settlementDate,
          documentScanDate: x.documentScanDate,
          documentOriginalDate: x.documentOriginalDate,
          purpose: x.purpose,
          hasPhotographicDocumentation: x.hasPhotographicDocumentation,
          actions: (
            <span
              className="editButton"
              onClick={() => redirectToEdit(x.dotationId)}
            >
              Edytuj
            </span>
          ),
        };
      });
      setItems(dataSource);
    });
  }, []);

  function redirectToEdit(dotationId) {
    history.push(`${client_path.dotationEdit}/${dotationId}`);
  }

  return (
    <>
      <GrantSheetMenu />
      <MDBContainer fluid>
        <DotationTable items={items} />
      </MDBContainer>
    </>
  );
};

export default DotationList;
