import React, { Component } from 'react';
import { MDBContainer } from 'mdbreact';
import CostTable from '../../features/cost/costTable';
import GrantSheetMenu from '../menu/menuNavBar';
import './cost.css'

class CostList extends Component {
    state = {}
    render() {
        return (
            <div>
                <GrantSheetMenu />
                <MDBContainer fluid>
                    <CostTable />
                </MDBContainer>

            </div>
        );
    }
}

export default CostList;