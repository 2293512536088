import React from "react";
import SummaryTable from "../../table/summaryTable";
import { MDBBtn } from "mdbreact";
import "./cash-report.css";
const cashReportTable = ({
  startBalance,
  totalIncome,
  totalCost,
  balance,
  balanceWithIncomes,
  reportItems,
  exportToExcelCallback,
}) => {
  const columns = [
    {
      header: "Data",
      field: "date",
      type: "date",
      orderType: "date",
    },
    { header: "Nr dokumentu", field: "documentNumber" },
    { header: "Subkonto", field: "projectName" },
    {
      header: "Przychód",
      field: "incomeValue",
      isSummary: true,
      type: "currency",
    },
    {
      header: "Rozchód",
      field: "costValue",
      type: "currency",
      isSummary: true,
    },
    { header: "Uwagi", field: "description" },
  ];

  return (
    <>
      <div className="cash-report-header">
        <div className="cash-report-header-table">
          <strong>Podsumowanie</strong>
          <table className="cash-report-table">
            <tbody>
              <tr>
                <td className="title-column">Bilans początkowy</td>
                <td className="income-column">{startBalance} zł</td>
                <td className="cost-column"></td>
              </tr>
              <tr>
                <td className="title-column">Suma przychodów</td>
                <td className="income-column">{totalIncome} zł</td>
                <td className="cost-column"></td>
              </tr>
              <tr>
                <td className="title-column">Suma rozchodów</td>
                <td className="income-column"></td>
                <td className="cost-column">{totalCost} zł</td>
              </tr>
              <tr>
                <td className="title-column">Razem:</td>
                <td className="income-column">{balanceWithIncomes} zł</td>
                <td className="cost-column">{totalCost} zł</td>
              </tr>
              <tr>
                <td className="title-column-summary">W kasie:</td>
                <td className="column-summary" colSpan={2}>
                  {balance} zł
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="export-excel-button">
          <MDBBtn color="success" size="sm" onClick={exportToExcelCallback}>
            Eskport do Excel
          </MDBBtn>
        </div>
        <div>
          <SummaryTable columns={columns} data={reportItems} />
        </div>
      </div>
    </>
  );
};

export default cashReportTable;
