import axios from 'axios';
import { toast } from 'react-toastify';
import authService from './authService';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
setHeader_token(authService.getTokenFromLocalStorage());

let errorCount = 0;
axios.interceptors.response.use(null, error => {
    const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;

    if (error.response.status === 401) {
        return window.location.href = '/loginPage'
    }

    if (!expectedError && errorCount === 0) {
        errorCount += 1;
        toast.error("Brak połączenia z serwerem.")
        setTimeout(function () { errorCount = 0 }, 2000)
    }
    return Promise.reject(error)
})


export function setHeader_token(token) {
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
}
export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    patch: axios.patch,
    delete: axios.delete,
    setHeader_token
}